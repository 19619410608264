import type { TranslationInterface } from "./interface.ts";

export const translationsUA: TranslationInterface = {
	index: { title: "Ласкаво просимо на індекс Remix" },

	accessories_page: {
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES_FOR: "Вибачте, у нас немає аксесуарів для",
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES:
			"Вибачте, у нас немає відповідних аксесуарів.",
	},

	buyer_page: {
		fields: {
			MIDDLE_NAME: { LABEL: "По батькові", ERROR_MESSAGE: "" },
			SUFFIX: {
				LABEL: "Суфікс",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "Пошук суфікса...",
			},
			COUNTY: { LABEL: "Район", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "Поштовий індекс", ERROR_MESSAGE: "" },
			CELL_PHONE: { LABEL: "Мобільний телефон", ERROR_MESSAGE: "" },
			WORK_PHONE: { LABEL: "Робочий телефон", ERROR_MESSAGE: "" },
			HOME_PHONE: { LABEL: "Домашній телефон", ERROR_MESSAGE: "" },
			EMAIL: { LABEL: "Електронна пошта", ERROR_MESSAGE: "" },
			"STREET_ADDRESS/PO_BOX": {
				LABEL: "Адреса/Поштова скринька",
				ERROR_MESSAGE: "",
			},
			"SUITE/APT": { LABEL: "Квартира/офіс", ERROR_MESSAGE: "" },
			FIRST_NAME: { LABEL: "Ім'я", ERROR_MESSAGE: "Ой! Ви мене пропустили!" },
			LAST_NAME: { LABEL: "Прізвище", ERROR_MESSAGE: "Ой! Не забудьте мене!" },
			STREET: {
				LABEL: "",
				ERROR_MESSAGE:
					"Заповніть мене. Ми обіцяємо, що не здивуємо вас візитом",
			},
			CITY: { LABEL: "Місто", ERROR_MESSAGE: "Пишіться своєю громадою!" },
			STATE: { LABEL: "Область", ERROR_MESSAGE: "Представте!" },
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Ми шукаємо 5-значний індекс.",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "",
				ERROR_MESSAGE: "ZipDeal потребує вашого індексу!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Будь ласка, введіть дійсний номер телефону",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "",
				ERROR_MESSAGE: "Гей, можна твій номер?",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Вам потрібно вибрати дату після {{- BIRTHDAY_MIN }}",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "Вам потрібно вибрати дату до {{- BIRTHDAY_MAX }}",
			},
			CUSTOMER_BIRTHDAY: {
				LABEL: "Дата народження",
				ERROR_MESSAGE: "Будь ласка, введіть дійсну дату",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "Нам потрібна ваша електронна пошта.",
			},
			CUSTOMER_EMAIL: {
				LABEL: "",
				ERROR_MESSAGE: "Це не схоже на електронну пошту.",
			},
		},

		KEEP_PHONE_HANDY:
			"(Тримайте мобільний телефон під рукою, наш перевіряючий страхування може телефонувати, щоб підтвердити ваше страхування)",
		TITLE_AND_REGISTRATION_INFORMATION:
			"Ваша інформація про титул та реєстрацію нижче повинна відповідати вашому посвідченню водія.",
		IF_HAVE_COBUYER_OR_COSIGNER:
			"Якщо у вас є співпокупець або співпідписант, ми запитаємо про це на наступному екрані.",
		IS_THERE_COBUYER_FOR_PURCHASE:
			"Чи є співпокупець або співпідписант при цій покупці?",
		INPUT_COBUYER_INFORMATION:
			"Якщо так, будь ласка, введіть їх повне ім'я та адресу тут",
		IN_ADDITION_TO_YOU:
			"Крім вас, чи буде хтось інший зазначений у реєстрації вашого нового автомобіля?",
		HAVE_COSIGNER: "Так, у мене є співпідписант",
		JUST_ME: "Ні, це лише я",
		NOT_COBUYER: "Тут немає співпокупця/співпідписанта",
	},
	common: {
		YES: "Так",
		NO: "Ні",
		BACK: "Назад",
		NEXT: "Далі",
		NO_THANK_YOU: "Ні, дякую",
		"YES!": "Так!",
		SCROLL_TO_REVEAL_MORE: "прокрутіть, щоб відкрити більше",
		PLEASE_SCROLL: "Будь ласка, прокрутіть, щоб продовжити",
	},
	care_card_page: {
		CARE_CARD_TEXT:
			"Ваш транспортний засіб є центральним у всьому вашому житті. Він перевозить вас та ваш важливий вантаж до роботи, спортивних заходів, відпусток, шкільних танців та інших важливих подій у житті. Більше не потрібно відкладати необхідний ремонт! {{ DEALER_NAME }} може допомогти вам переконатися, що ваше життя не зупиниться з їх {{ CARE_CARD_NAME }}",
		INQUIRE_TODAY:
			"Запитуйте сьогодні у вашого продавця або фінансового фахівця!",
	},

	collision_center_page: {
		AFTER_CAR_ACCIDENT: "10 речей, які ви повинні зробити після аварії",
		WE_WILL_TEXT_YOU:
			"Ми надішлемо вам контактну інформацію та ці 10 важливих кроків, щоб у вас було їх безпечно та надійно в телефоні, якщо вам це знадобиться!",
		"RULE#1":
			"#1 – Переконайтеся, що всі в порядку, і викличте 911, якщо травми цього вимагають",
		"RULE#2": "#2 – Захистіть себе та інших, захистивши місце події",
		"RULE#3": "#3 – Викличте поліцію, незалежно від травм або вини",
		"RULE#4":
			"#4 – Зафіксуйте деталі події, поки вони свіжі – використовуйте свій смартфон",
		"RULE#5": "#5 – Обміняйтеся інформацією з усіма сторонами",
		"RULE#6": "#6 – Повідомте про аварію, як тільки прибуде поліція",
		"RULE#7":
			"#7 – Зробіть фотографії всіх транспортних засобів, що брали участь, та місця події",
		"RULE#8": "#8 – Зателефонуйте до своєї страхової компанії та/або агента",
		"RULE#9": "#9 – Огляньте себе у місцевому медичному закладі",
		"RULE#10":
			"#10 – Зберігайте всю документацію, пов'язану з аварією, в одному файлі",
	},

	congratulation_page: {
		SALES_PROFESSIONAL_WAS_NOTIFIED:
			"Ваш професійний менеджер з продажу був повідомлений і буде з вами незабаром!",
		RELAX_AND_ENJOY_VIDEOS: "Розслабтесь та насолоджуйтесь відео.",
		NOT_ALL_APPLICABLE_TO_ALL_MODELS: "Не всі функції можливі для всіх моделей",
	},
	connected_car_page: {
		NO_CONNECTED_CAR_APP:
			"Вибачте, у нас немає додатка для підключення автомобіля для автомобілів {{vehicleMake}}.",
	},
	feature_focus_page: {
		FEEL_FREE_TO_CLICK_TO_HIGHLIGHT:
			"Не соромтеся натиснути, щоб виділити будь-яку з категорій інформації про автомобіль нижче, про які ви хочете дізнатися більше під час остаточної орієнтації на вашу <span class='font-bold'> {{ vehicleYear }} {{ vehicleMake}} {{ vehicleModel }} </span> доставку",
	},
	fsa_page: {
		SCHEDULE_FSA: "Запланувати ваш перший сервісний візит",
		WHAT_TIME_WORKS_BEST: "Який час найкраще підходить?",
		WORRIED_ABOUT_COMMITMENT:
			"Турбуєтесь про зобов'язання через {{ timeToAppointment }} місяці? Не треба! Ви зможете перепланувати за своїм розсудом, коли наблизиться дата.",
		SELECTED_DATE_AVAILABILITY:
			"Доступність обраної дати: {{ engagedCapacity }}/{{ capacity }} слоти взяті.",
		SELECTED_FSA_FOR: "Ви обрали свій перший сервісний візит для",
		CHANGE_DATE_OR_TIME: "Я хочу змінити дату або час",
		AT: "на",
		SUN: "НД",
		MON: "ПН",
		TUE: "ВТ",
		WED: "СР",
		THU: "ЧТ",
		FRI: "ПТ",
		SAT: "СБ",
		JANUARY: "Січень",
		FEBRUARY: "Лютий",
		MARCH: "Березень",
		APRIL: "Квітень",
		MAY: "Травень",
		JUNE: "Червень",
		JULY: "Липень",
		AUGUST: "Серпень",
		SEPTEMBER: "Вересень",
		OCTOBER: "Жовтень",
		NOVEMBER: "Листопад",
		DECEMBER: "Грудень",
	},
	insurance_page: {
		fields: {
			INSURANCE_STATUS: {
				LABEL:
					"Будь ласка, виберіть статус страхування, який найкраще вас відображає",
				ERROR_MESSAGE: "",
				CHOICES: {
					I_HAVE_INSURANCE: "Я маю страхування",
					I_AM_EXISTING_CUSTOMER:
						"Я є існуючим клієнтом від {{ INHOUSE_AGENCY_NAME }} і хочу замінити/додати цей автомобіль до моєї наявної поліси.",
					I_DO_NOT_HAVE_INSURANCE_SETUP: "Я не маю налаштованого страхування",
				},
			},
			NAME_OF_INSURED: { LABEL: "Ім'я страхового", ERROR_MESSAGE: "" },
			POLICY_NUMBER: { LABEL: "Номер полісу", ERROR_MESSAGE: "" },
			POLICY_START_DATE: { LABEL: "Дата початку полісу", ERROR_MESSAGE: "" },
			POLICY_END_DATE: { LABEL: "Дата закінчення полісу", ERROR_MESSAGE: "" },
			EMAIL: { LABEL: "Електронна пошта", ERROR_MESSAGE: "" },
			CITY: { LABEL: "Місто", ERROR_MESSAGE: "" },
			STATE: { LABEL: "Область", ERROR_MESSAGE: "" },
			COUNTY: { LABEL: "Район", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "Поштовий індекс", ERROR_MESSAGE: "" },
			BUYER_ADDRESS: { LABEL: "Адреса покупця", ERROR_MESSAGE: "" },
			BUYER_PHONE_NUMBER: { LABEL: "Телефон покупця", ERROR_MESSAGE: "" },
			INSURER: {
				LABEL: "Страховик {{ INHOUSE_AGENCY_NAME }}",
				ERROR_MESSAGE: "",
			},
			INSURANCE_CARRIER: {
				LABEL: "Страховий перевізник",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "Виберіть перевізника...",
			},
			CARRIER_ADDRESS: { LABEL: "Адреса перевізника", ERROR_MESSAGE: "" },
			AGENT_NAME: {
				LABEL:
					"Ім'я/прізвище агента (якщо агента немає, будь ласка, введіть 'ПРЯМО')",
				ERROR_MESSAGE: "",
			},
			AGENT_PHONE_NUMBER: { LABEL: "Телефон агента", ERROR_MESSAGE: "" },
			EMPTY_ERROR_MESSAGE: " ",
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Будь ласка, введіть дійсний номер телефону",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Вам потрібно вибрати дату після {{- BIRTHDAY_MIN }}",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "Вам потрібно вибрати дату до {{- BIRTHDAY_MAX }}",
			},
			POLICY_START_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Вам потрібно вибрати дату після {{- POLICY_START_MIN }}",
			},
			POLICY_START_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "Вам потрібно вибрати дату до {{- POLICY_START_MAX }}",
			},
			POLICY_EXPIRATION_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Вам потрібно вибрати дату після {{- POLICY_EXPIRATION_MIN }}",
			},
			POLICY_EXPIRATION_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Вам потрібно вибрати дату до {{- POLICY_EXPIRATION_MAX }}",
			},
			ENTER_VALID_DATE: {
				LABEL: "",
				ERROR_MESSAGE: "Будь ласка, введіть дійсну дату",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "Нам потрібна ваша електронна пошта.",
			},
			CUSTOMER_EMAIL: {
				LABEL: "",
				ERROR_MESSAGE: "Це не схоже на електронну пошту.",
			},
		},
		I_DO_NOT_SEE_MY_CARRIER: "Я не бачу свого перевізника",
		POLICY_INFORMATION: "Інформація про поліс",
		BUYER_INFORMATION: "Інформація про покупця",
		CARRIER_INFORMATION: "Інформація про перевізника",
		AGENT_INFORMATION: "Інформація про агента",
	},
	referral_page: {
		NAME_1: "Ім'я Реферала #1",
		CELL_PHONE: "Номер Мобільного",
		EMAIL: "Електронна Пошта",
		NAME_2: "Ім'я Реферала #2",
		NAME_3: "Ім'я Реферала #3",
	},
	review_page: {
		YOUR_FEEDBACK_IMPORTANT: "Ваш відгук важливий для нас!",
		HOW_YOU_RATE_US:
			"Як би ви оцінили свій досвід в {{ dealershipName }}, на даний момент?",
		PLEASE_RATE_US: "Будь ласка, поставте нам від 1 до 5 зірок",
		WE_ARE_DISAPPOINTED:
			"Ми розчаровані, що ваш досвід не відповів вашим очікуванням. Ми хотіли б почути, що ми могли зробити, щоб зробити ваш досвід кращим.",
		WE_ARE_SORRY:
			"Нам шкода, що ми не виправдали ваші очікування до цього моменту. Якщо ви готові, ми будемо вдячні, якщо поділитесь своїми думками про те, де ми можемо покращити.",
		WE_WILL_TEXT_YOU:
			"Якщо так, ми відправимо вам посилання текстовим повідомленням, щоб це було легко!",
		SHARE_YOUR_EXPERIENCE:
			"Чи не будете ви заперечувати взяти хвилинку, щоб поділитися своїм досвідом через відгук {{name}}?",
		WE_APPRECIATE_REVIEW: "Ми цінуємо відгук!",
		REVIEW_LINK_WAS_TEXTED_TO_YOU:
			"Посилання на відгук {{name}} було відправлено вам текстовим повідомленням. Ми вельми вдячні вам за поділ свого досвіду!",
		CONTINUE_ON: "Продовжити",
		LET_US_KNOW: "Дайте нам знати, що ми можемо зробити краще",
		COMMENTS: "Коментарі...",
		CHANGE_MY_RATING: "Змінити мою оцінку",
		NO_COMMENT: "Без коментарів.",
		SEND_MY_FEEDBACK: "Надіслати мої відгуки",
	},
	sales_page: {
		fields: {
			DMS_OMNI_NUMBER: {
				LABEL: "Введіть {{usesDealNumber}}Stock # або VIN #",
				ERROR_MESSAGE: "Введіть номер для пошуку.",
				BUTTON: "Завантажити",
			},
			DMS_OMNI_NUMBER_RV: {
				LABEL: "Введіть номер угоди",
				ERROR_MESSAGE: "Введіть номер для пошуку.",
				BUTTON: "Завантажити",
			},
			DEAL_NUMBER: { LABEL: "Номер угоди", ERROR_MESSAGE: "" },
			CHOOSE_DEAL_TYPE: { LABEL: "Виберіть тип угоди", ERROR_MESSAGE: "" },
			STOCK_NUMBER: {
				LABEL: "Номер на складі",
				ERROR_MESSAGE: "Що продається?",
				BUTTON: "Завантажити з Inv",
			},
			VEHICLE_CLASS: {
				LABEL: "Тип транспортного засобу",
				ERROR_MESSAGE: "Який тип транспортного засобу?",
				PLACEHOLDER: "Пошук класу транспортного засобу...",
			},
			SALES_MANAGER: {
				LABEL: "№ продавця",
				PLACEHOLDER: "Пошук фахівця з продажу....",
			},
			SALES_PROFESSIONAL_FIRST_NAME: {
				LABEL: "Ім'я фахівця з продажу",
				ERROR_MESSAGE: "",
			},
			SALES_PROFESSIONAL_LAST_NAME: {
				LABEL: "Прізвище фахівця з продажу",
				ERROR_MESSAGE: "",
			},
			CELL_PHONE_FOR_TEXT_ALERTS: {
				LABEL: "Мобільний телефон для текстових сповіщень",
				ERROR_MESSAGE: "",
			},

			FIRST_NAME: { LABEL: "", ERROR_MESSAGE: "Ім'я є обов'язковим" },
			LAST_NAME: { LABEL: "", ERROR_MESSAGE: "Прізвище є обов'язковим" },

			DMS_DEAL_NUMBER: {
				LABEL: "Номер угоди DMS",
				ERROR_MESSAGE: "Не забудь мене!",
				BUTTON: "Завантажити з DMS",
			},
			DESK_MANAGER: {
				LABEL: "Керівник відділу продажу",
				ERROR_MESSAGE: "Хто керує цією операцією?!",
				PLACEHOLDER: "Пошук керівника з продажу...",
			},
			TRADE_IN_QUESTIONNAIRE: {
				LABEL: "",
				ERROR_MESSAGE: "Чи обмінюють вони щось?",
			},
			SALES_CONSULTANT_1: {
				LABEL: "",
				ERROR_MESSAGE:
					"Залиш мене порожнім, щоб пожертвувати свою комісію на благодійність.",
			},
			VIN: {
				LABEL: "VIN # транспортного засобу",
				ERROR_MESSAGE: "Введіть мій генетичний DNA, будь ласка.",
			},
			VIN_MIN: { LABEL: "", ERROR_MESSAGE: "Це занадто коротке для VIN." },
			VIN_MAX: { LABEL: "", ERROR_MESSAGE: "Це занадто довге для VIN." },
			YEAR: {
				LABEL: "Рік",
				ERROR_MESSAGE: "Скільки свічок на моєму торті?",
				PLACEHOLDER: "Виберіть рік...",
			},
			MAKE: {
				LABEL: "Марка",
				ERROR_MESSAGE: "Хто зробив мене?",
				PLACEHOLDER: "Виберіть марку...",
				LOADING: "ЗАВАНТАЖЕННЯ МАРОК...",
			},
			MODEL: {
				LABEL: "Модель",
				ERROR_MESSAGE: "Не залишай мене тут!",
				PLACEHOLDER: "Виберіть модель...",
				LOADING: "ЗАВАНТАЖЕННЯ МОДЕЛЕЙ...",
			},
			TRIM: {
				LABEL: "Комплектація",
				ERROR_MESSAGE: "Не забудь, що в мене особливого.",
				PLACEHOLDER: "Виберіть комплектацію...",
				MODEL_FIRST: "Спочатку виберіть модель.",
				LOADING: "ЗАВАНТАЖЕННЯ КОМПЛЕКТАЦІЙ...",
			},
			DOES_HAVE_TRADE_IN: {
				LABEL: "Чи є у цієї угоди обмін?",
				ERROR_MESSAGE: "",
				CHOICES: {
					YES: "Так",
					NO: "Ні",
				},
			},
			DEALERSHIP_CHOICE: {
				LABEL: "",
				ERROR_MESSAGE: "Для кого ми робимо цю угоду?",
			},
			CONNECTED_CAR_PHONE_APP: {
				LABEL: "Додаток для зв'язаного автомобіля",
				ERROR_MESSAGE: "Гей, я розумний автомобіль?",
				CHOICES: {
					YES: "Так",
					NO: "Ні",
				},
			},
			CONNECTED_PHONE_APP: {
				LABEL: "З'єднаний додаток для телефону",
				ERROR_MESSAGE: "Чи є у мене додаток для телефону?",
				CHOICES: {
					YES: "Так",
					NO: "Ні",
				},
			},
			DIGITAL_DELIVERY_TYPE: {
				LABEL: "",
				ERROR_MESSAGE: "Яким чином ви хочете отримувати сповіщення?",
			},
			CUSTOMER_FIRST_NAME: {
				LABEL: "Ім'я клієнта",
				ERROR_MESSAGE: "Ой! Ви мене пропустили!",
			},
			CUSTOMER_LAST_NAME: {
				LABEL: "Прізвище клієнта",
				ERROR_MESSAGE: "Гой! Не забудьте мене!",
			},
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Ми шукаємо 5-значний Zip.",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "Поштовий індекс клієнта",
				ERROR_MESSAGE: "ZipDeal потрібен поштовий індекс клієнта!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Будь ласка, введіть дійсний номер телефону",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "Мобільний телефон клієнта",
				ERROR_MESSAGE: "Нам потрібні їх цифри!",
			},
			CUSTOMER_EMAIL: { LABEL: "", ERROR_MESSAGE: "Яка їх електронна пошта?" },
			FINANCE_TYPE: {
				LABEL: "Тип фінансування",
				ERROR_MESSAGE: "Гей, як вони мене оплачують?",
				CHOICES: {
					FINANCE: "Фінансування",
					LEASE: "Лізинг",
					CASH: "Готівка",
				},
			},
		},
		CANT_FIND_NAME: "Не можете знайти ім'я? Додайте його зараз!",
		DO_NOT_HAVE_DEAL_NUMBER: "У мене немає номера угоди",
		TEXT_ALERT_TO: "Текстові сповіщення надходитимуть на",
		ODOMETER: "Одометр",
		ADD_SALES_PROFESSIONAL: "Додати нового фахівця з продажу",
		MAYBE_NOT: "Можливо, ні.",
		ADD_ME: "Додай мене!",
	},
	welcome_page: {
		BY_CLICKING_NEXT:
			"*Натискаючи 'ДАЛІ', ви дозволяєте {{ DEALER_NAME }} надсилати важливу інформацію про власність через текст.",
	},
	radio_presets_page: {
		PRESETS: "Попередні налаштування",
		CHOOSE_BELOW: "Виберіть #{{ number }} нижче",
		CLICK_YOUR_STATION: "Клацніть на свою станцію, щоб додати її!",
		CHOOSE_WISELY:
			"Ви не можете вибрати стільки попередніх налаштувань! Вам доведеться видалити одне, перш ніж додавати інше. Обирайте розумно.",
	},
	zdzFsa: {
		DATE_AND_TIME:
			"Ваш перший сервісний візит запланований на {{ date }} о {{time}}.",
		INSTRUCTIONS:
			"Ви можете додати до календаря на свій вибір, вибравши зі спадного меню нижче!",
		NOTE: "Примітка: Щоб додати до календаря Apple на вашому iPhone, цю сторінку потрібно переглядати за допомогою Safari.",
		NOT_FOUND: "Деталі першого сервісного візиту не знайдені",
		GREETING: "Привіт, {{name}}!",
		EVENT_NAME: "Перший сервісний візит у {{dealership}}",
	},
	AddToCalendarComponent: {
		PLACEHOLDER: "Вибрати календар",
	},
};

export default translationsUA;
