import type { TranslationInterface } from "./interface.ts";

export const translationsES: TranslationInterface = {
	index: { title: "Bienvenido a Remix es" },

	accessories_page: {
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES_FOR:
			"Lo sentimos, no tenemos accesorios para el",
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES:
			"Lo sentimos, no tenemos accesorios a juego.",
	},

	buyer_page: {
		fields: {
			MIDDLE_NAME: { LABEL: "Segundo Nombre", ERROR_MESSAGE: "" },
			SUFFIX: {
				LABEL: "Suffix",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "Buscar un sufijo...",
			},
			COUNTY: { LABEL: "Condado", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "Código Postal", ERROR_MESSAGE: "" },
			CELL_PHONE: { LABEL: "Número Celular", ERROR_MESSAGE: "" },
			WORK_PHONE: {
				LABEL: "Número de teléfono del trabajo",
				ERROR_MESSAGE: "",
			},
			HOME_PHONE: { LABEL: "Número de teléfono de casa", ERROR_MESSAGE: "" },
			EMAIL: { LABEL: "Email", ERROR_MESSAGE: "" },
			"STREET_ADDRESS/PO_BOX": {
				LABEL: "Dirección/Apartado de correos",
				ERROR_MESSAGE: "",
			},
			"SUITE/APT": { LABEL: "Suite/Apt", ERROR_MESSAGE: "" },

			FIRST_NAME: {
				LABEL: "Primer Nombre",
				ERROR_MESSAGE: "¡Ups! ¡Me extrañaste!",
			},
			LAST_NAME: { LABEL: "Apellido", ERROR_MESSAGE: "¡Ups! ¡No me olvides!" },
			STREET: {
				LABEL: "",
				ERROR_MESSAGE: "Relléname. No prometemos visitas sorpresa",
			},
			CITY: {
				LABEL: "Ciudad",
				ERROR_MESSAGE: "¡Muestra el orgullo de tu comunidad!",
			},
			STATE: { LABEL: "Estado", ERROR_MESSAGE: "¡Representar!" },
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Buscamos un código postal de 5 dígitos.",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "",
				ERROR_MESSAGE: "¡ZipDeal necesita tu código postal!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Ingrese un número de teléfono válido",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "",
				ERROR_MESSAGE: "¡Oye, tú! ¿Puedo obtener tus dígitos?",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Debe seleccionar una fecha posterior a {{- BIRTHDAY_MIN }}",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Debes seleccionar una fecha antes del {{- BIRTHDAY_MAX }}",
			},
			CUSTOMER_BIRTHDAY: {
				LABEL: "Fecha de Nacimiento",
				ERROR_MESSAGE: "Ingrese una fecha válida",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "Necesitamos tu correo electrónico.",
			},
			CUSTOMER_EMAIL: {
				LABEL: "",
				ERROR_MESSAGE: "Esto no parece un correo electrónico.",
			},
		},

		KEEP_PHONE_HANDY:
			"(Mantenga su teléfono celular a mano, nuestro verificador de seguros puede estar llamando para confirmar su seguro)",
		TITLE_AND_REGISTRATION_INFORMATION:
			"Tu título y la información de registro a continuación deben reflejar tu licencia de conducir.",
		IF_HAVE_COBUYER_OR_COSIGNER:
			"Si tiene un cocomprador o cosignatario, le preguntaremos sobre eso en la siguiente pantalla.",
		IS_THERE_COBUYER_FOR_PURCHASE:
			"¿Hay un Co-Comprador o Co-Firmante en esta compra?",
		INPUT_COBUYER_INFORMATION:
			"En caso afirmativo, ingrese su nombre completo y la información de la dirección aquí",
		IN_ADDITION_TO_YOU:
			"Además de usted, ¿se nombrará a alguien más en el registro de su nuevo vehículo?",
		HAVE_COSIGNER: "Sí, tengo un co-firmante",
		JUST_ME: "No, solo soy yo",
		NOT_COBUYER: "No hay Co-Comprador/Co-Firmante",
	},
	care_card_page: {
		CARE_CARD_TEXT:
			"Tu vehículo es el centro de todo en tu vida. Te transporta a ti y a tu preciada carga hacia y desde el trabajo, eventos deportivos, vacaciones, bailes escolares y otros hitos importantes de la vida. Ya no hay necesidad de posponer esas reparaciones necesarias. {{ DEALER_NAME }} puede ayudarte a asegurarte de que tu vida no deje de moverse con su {{ CARE_CARD_NAME }}",
		INQUIRE_TODAY: "¡Consulte hoy con su profesional de ventas o finanzas!",
	},

	collision_center_page: {
		AFTER_CAR_ACCIDENT:
			"Las 10 cosas que debes hacer después de un accidente automovilístico",
		WE_WILL_TEXT_YOU:
			"¡Te enviaremos un mensaje de texto con la información de contacto y estos 10 pasos importantes para que la tengas segura en tu teléfono en caso de que la necesites!",
		"RULE#1":
			"#1: Asegúrate de que todos estén bien y llama al 911 si las lesiones lo justifican",
		"RULE#2":
			"#2 – Mantente a ti mismo y a los demás a salvo protegiendo la escena",
		"RULE#3":
			"#3 – Llame a la policía, independientemente de las lesiones o la culpa",
		"RULE#4":
			"#4 – Documenta los detalles del evento mientras estás fresco – usa tu smartphone",
		"RULE#5": "#5 – Intercambiar información con todas las partes",
		"RULE#6": "#6 – Reporta el accidente una vez que llegue la policía",
		"RULE#7": "#7 – Toma fotos de todos los vehículos involucrados y la escena",
		"RULE#8": "#8 – Llame a su Compañía de Seguros y/o Agente",
		"RULE#9": "#9 – Ve a que te revisen en tu centro de atención local",
		"RULE#10":
			"#10 – Mantenga un archivo de toda la documentación relacionada con el accidente",
	},
	common: {
		YES: "Sí",
		NO: "No",
		BACK: "Atrás",
		NEXT: "Siguiente",
		NO_THANK_YOU: "No gracias",
		"YES!": "Si!",
		SCROLL_TO_REVEAL_MORE: "desplácese para revelar más",
		PLEASE_SCROLL: "Por favor desplácese para continuar",
	},
	congratulation_page: {
		SALES_PROFESSIONAL_WAS_NOTIFIED:
			"¡Su profesional de ventas ha sido notificado y estará con usted en breve!",
		RELAX_AND_ENJOY_VIDEOS: "Relájate y disfruta de algunos videos.",
		NOT_ALL_APPLICABLE_TO_ALL_MODELS:
			"No todas las características son aplicables a todos los modelos",
	},
	connected_car_page: {
		NO_CONNECTED_CAR_APP:
			"Lo sentimos, no tenemos una aplicación de automóvil conectado para {{vehicleMake}} vehículos.",
	},
	feature_focus_page: {
		FEEL_FREE_TO_CLICK_TO_HIGHLIGHT:
			"Siéntase libre de hacer clic para resaltar cualquiera de las categorías de información del vehículo a continuación sobre las que le gustaría obtener más información durante la orientación final del vehículo en su <span class='font-bold'> {{ vehicleYear }} {{ vehicleMake}} {{ vehicleModel }}</span> entrega",
	},
	fsa_page: {
		SCHEDULE_FSA: "Programa tu Primera Cita de Servicio",
		WHAT_TIME_WORKS_BEST: "¿A qué hora funciona mejor?",
		WORRIED_ABOUT_COMMITMENT:
			"¿Te preocupa hacer un compromiso dentro de {{ timeToAppointment }} meses? ¡No lo hagas! Podrás reprogramar cuando quieras a medida que se acerque la fecha.",
		SELECTED_DATE_AVAILABILITY:
			"Disponibilidad para la fecha seleccionada: {{ engagedCapacity }}/{{ capacity }} espacios ocupados.",
		SELECTED_FSA_FOR: "Ha seleccionado su Primera Cita de Servicio para",
		CHANGE_DATE_OR_TIME: "Quiero cambiar la fecha o la hora",
		AT: "a las",
		SUN: "DOM",
		MON: "LUN",
		TUE: "MAR",
		WED: "MIÈ",
		THU: "JUE",
		FRI: "VIE",
		SAT: "SÁB",
		JANUARY: "Enero",
		FEBRUARY: "Febrero",
		MARCH: "Marzo",
		APRIL: "Abril",
		MAY: "Mayo",
		JUNE: "Junio",
		JULY: "Julio",
		AUGUST: "Agosto",
		SEPTEMBER: "Septiembre",
		OCTOBER: "Octubre",
		NOVEMBER: "Noviembre",
		DECEMBER: "Diciembre",
	},
	insurance_page: {
		fields: {
			INSURANCE_STATUS: {
				LABEL: "Seleccione el estado del seguro que mejor refleje el suyo",
				ERROR_MESSAGE: "",
				CHOICES: {
					I_HAVE_INSURANCE: "Tengo seguro",
					I_AM_EXISTING_CUSTOMER:
						"Soy un cliente existente con {{ INHOUSE_AGENCY_NAME}} y me gustaría reemplazar/agregar este vehículo a mi póliza actual.",
					I_DO_NOT_HAVE_INSURANCE_SETUP: "No tengo configuración de seguro",
				},
			},
			NAME_OF_INSURED: { LABEL: "Nombre del Asegurado", ERROR_MESSAGE: "" },
			POLICY_NUMBER: { LABEL: "Número de Póliza", ERROR_MESSAGE: "" },
			POLICY_START_DATE: {
				LABEL: "Fecha de Inicio de la póliza",
				ERROR_MESSAGE: "",
			},
			POLICY_END_DATE: {
				LABEL: "Fecha de finalización de la póliza",
				ERROR_MESSAGE: "",
			},
			EMAIL: { LABEL: "Email", ERROR_MESSAGE: "" },
			CITY: { LABEL: "Ciudad", ERROR_MESSAGE: "" },
			STATE: { LABEL: "Estado", ERROR_MESSAGE: "" },
			COUNTY: { LABEL: "Condado", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "Código Postal", ERROR_MESSAGE: "" },
			BUYER_ADDRESS: { LABEL: "Dirección del Comprador", ERROR_MESSAGE: "" },
			BUYER_PHONE_NUMBER: {
				LABEL: "Número de teléfono del comprador",
				ERROR_MESSAGE: "",
			},
			INSURER: {
				LABEL: "{{ INHOUSE_AGENCY_NAME }} Aseguradora",
				ERROR_MESSAGE: "",
			},
			INSURANCE_CARRIER: {
				LABEL: "Compañía de Seguros",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "Seleccione un transportista...",
			},
			CARRIER_ADDRESS: { LABEL: "Dirección del operador", ERROR_MESSAGE: "" },
			AGENT_NAME: {
				LABEL:
					"Nombre/apellido del agente (si no hay agente, escriba 'DIRECTO')",
				ERROR_MESSAGE: "",
			},
			AGENT_PHONE_NUMBER: {
				LABEL: "Número de teléfono del agente",
				ERROR_MESSAGE: "",
			},

			EMPTY_ERROR_MESSAGE: " ",
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Ingrese un número de teléfono válido",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Debe seleccionar una fecha posterior a {{- BIRTHDAY_MIN }}",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Debes seleccionar una fecha antes del {{- BIRTHDAY_MAX }}",
			},
			POLICY_START_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Debe seleccionar una fecha posterior a {{- POLICY_START_MIN }}",
			},
			POLICY_START_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Debe seleccionar una fecha anterior al {{- POLICY_START_MAX }}",
			},
			POLICY_EXPIRATION_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Debe seleccionar una fecha posterior a {{- POLICY_EXPIRATION_MIN }}",
			},
			POLICY_EXPIRATION_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Debes seleccionar una fecha antes del {{- POLICY_EXPIRATION_MAX }}",
			},
			ENTER_VALID_DATE: {
				LABEL: "",
				ERROR_MESSAGE: "Ingrese una fecha válida",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "Necesitamos tu correo electrónico.",
			},
			CUSTOMER_EMAIL: {
				LABEL: "",
				ERROR_MESSAGE: "Esto no parece un correo electrónico",
			},
		},

		I_DO_NOT_SEE_MY_CARRIER: "No veo mi operador",
		POLICY_INFORMATION: "Información de la póliza",
		CARRIER_INFORMATION: "Información del transportista",
		BUYER_INFORMATION: "Información del comprador",
		AGENT_INFORMATION: "Información del agente",
	},
	referral_page: {
		NAME_1: "Nombre de Referencia #1",
		CELL_PHONE: "Número de Celular",
		EMAIL: "Correo Electrónico",
		NAME_2: "Nombre de Referencia #2",
		NAME_3: "Nombre de Referencia #3",
	},
	review_page: {
		YOUR_FEEDBACK_IMPORTANT: "¡Sus comentarios son importantes para nosotros!",
		HOW_YOU_RATE_US:
			"¿Cómo calificaría su experiencia en {{ dealershipName }}, hasta ahora?",
		PLEASE_RATE_US: "Califícanos de 1 a 5 estrellas",
		WE_ARE_DISAPPOINTED:
			"Estamos decepcionados de que su experiencia no cumpliera con sus expectativas. Nos encantaría saber qué podríamos haber hecho para mejorar su experiencia.",
		WE_ARE_SORRY:
			"Lamentamos no haber estado a la altura de sus expectativas hasta ahora. Si está dispuesto, le agradecemos que comparta sus pensamientos sobre dónde podemos mejorar.",
		WE_WILL_TEXT_YOU:
			"¡Si es así, te enviaremos un mensaje de texto con un enlace para que sea más fácil!",
		SHARE_YOUR_EXPERIENCE:
			"¿Le importaría tomarse un momento para compartir su experiencia a través de una revisión de {{name}}?",
		WE_APPRECIATE_REVIEW: "¡Agradecemos la revisión!",
		REVIEW_LINK_WAS_TEXTED_TO_YOU:
			"Se le envió un mensaje de texto con el enlace de revisión de {{name}}. ¡Agradecemos mucho que comparta su experiencia!",
		CONTINUE_ON: "Continuar en",
		LET_US_KNOW: "Háganos saber qué podemos hacer mejor",
		COMMENTS: "Comentarios...",
		CHANGE_MY_RATING: "Cambiar mi calificación",
		NO_COMMENT: "Sin comentarios.",
		SEND_MY_FEEDBACK: "Enviar mis comentarios",
	},
	sales_page: {
		fields: {
			DMS_OMNI_NUMBER: {
				LABEL: "Número de Trato/Inventario/VIN ",
				ERROR_MESSAGE: "Introduzca un número para buscar.",
				BUTTON: "Carga",
			},
			DMS_OMNI_NUMBER_RV: {
				LABEL: "Ingrese un número de oferta",
				ERROR_MESSAGE: "Introduzca un número para buscar.",
				BUTTON: "Carga",
			},
			CONNECTED_PHONE_APP: {
				LABEL: "Aplicación de teléfono conectado",
				ERROR_MESSAGE: "¿Tengo una aplicación de teléfono?",
				CHOICES: {
					YES: "Sí",
					NO: "No",
				},
			},
			DEAL_NUMBER: { LABEL: "Número de trato", ERROR_MESSAGE: "" },
			CHOOSE_DEAL_TYPE: { LABEL: "Elija el tipo de oferta", ERROR_MESSAGE: "" },
			STOCK_NUMBER: {
				LABEL: "Número de inventario",
				ERROR_MESSAGE: "¿Qué está siendo vendido?",
				BUTTON: "Cargar desde el inv",
			},
			VEHICLE_CLASS: {
				LABEL: "Tipo de vehiculo",
				ERROR_MESSAGE: "¿Cuál es el tipo de vehículo?",
				PLACEHOLDER: "Buscar clase de vehículo...",
			},
			SALES_MANAGER: {
				LABEL: "Vendedor #",
				PLACEHOLDER: "Buscar un profesional de ventas....",
			},
			SALES_PROFESSIONAL_FIRST_NAME: {
				LABEL: "Nombre del profesional de ventas",
				ERROR_MESSAGE: "",
			},
			SALES_PROFESSIONAL_LAST_NAME: {
				LABEL: "Apellido del profesional de ventas",
				ERROR_MESSAGE: "",
			},
			CELL_PHONE_FOR_TEXT_ALERTS: {
				LABEL: "Teléfono celular para alertas de texto",
				ERROR_MESSAGE: "",
			},

			FIRST_NAME: { LABEL: "", ERROR_MESSAGE: "Se requiere el primer nombre" },
			LAST_NAME: { LABEL: "", ERROR_MESSAGE: "Se requiere apellido" },

			DMS_DEAL_NUMBER: {
				LABEL: "DMS Número de trato",
				ERROR_MESSAGE: "¡No me olvides!",
				BUTTON: "Cargar desde DMS",
			},
			DESK_MANAGER: {
				LABEL: "Gerente de Escritorio",
				ERROR_MESSAGE: "¡¿Quién está a cargo de esta operación?!",
				PLACEHOLDER: "Buscar gerente de ventas...",
			},
			TRADE_IN_QUESTIONNAIRE: {
				LABEL: "",
				ERROR_MESSAGE: "¿Están intercambiando algo?",
			},
			SALES_CONSULTANT_1: {
				LABEL: "",
				ERROR_MESSAGE: "Déjame en blanco para donar tu comisión a la caridad.",
			},
			VIN: {
				LABEL: "Número de VIN del vehículo",
				ERROR_MESSAGE: "Introduce mi ADN genético, por favor.",
			},
			VIN_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Esto es demasiado corto para un VIN",
			},
			VIN_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "Esto es demasiado largo para ser un VIN",
			},
			CONNECTED_CAR_PHONE_APP: {
				LABEL: "Aplicación de coche conectado",
				ERROR_MESSAGE: "Oye, ¿soy un auto inteligente?",
				CHOICES: {
					YES: "Sí",
					NO: "No",
				},
			},
			YEAR: {
				LABEL: "Año",
				ERROR_MESSAGE: "¿Cuántas velas hay en mi pastel de cumpleaños?",
				PLACEHOLDER: "Selecciona un año...",
			},
			MAKE: {
				LABEL: "Marca",
				ERROR_MESSAGE: "¿Quién me hizo?",
				PLACEHOLDER: "Seleccione una marca...",
				LOADING: "CARGANDO MARCAS...",
			},
			MODEL: {
				LABEL: "Modelo",
				ERROR_MESSAGE: "¡No me dejes colgado aquí!",
				PLACEHOLDER: "Selecciona un modelo...",
				LOADING: "LOADING MODELOS...",
			},
			TRIM: {
				LABEL: "Ajuste",
				ERROR_MESSAGE: "No olvides lo que tengo de especial.",
				PLACEHOLDER: "Seleccione un ajuste...",
				MODEL_FIRST: "Seleccione un modelo primero.",
				LOADING: "LOADING AJUSTES...",
			},
			DEALERSHIP_CHOICE: {
				LABEL: "",
				ERROR_MESSAGE: "¿Para quién estamos haciendo este trato?",
			},
			DIGITAL_DELIVERY_TYPE: {
				LABEL: "",
				ERROR_MESSAGE: "¿De qué manera deseas que te notifiquen?",
			},
			CUSTOMER_FIRST_NAME: {
				LABEL: "Nombre del cliente",
				ERROR_MESSAGE: "¡Ups! ¡Me extrañaste!",
			},
			CUSTOMER_LAST_NAME: {
				LABEL: "Apellido del cliente",
				ERROR_MESSAGE: "¡Vaya! ¡No me olvides!",
			},
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Buscamos un código postal de 5 dígitos.",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "Código Postal del Cliente",
				ERROR_MESSAGE: "¡ZipDeal necesita el código postal del cliente!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Ingrese un número de teléfono válido",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "Teléfono Celular del Cliente",
				ERROR_MESSAGE: "¡Necesitamos sus dígitos!",
			},
			CUSTOMER_EMAIL: { LABEL: "", ERROR_MESSAGE: "¿Cuál es su email?" },
			FINANCE_TYPE: {
				LABEL: "Tipo de Financiación",
				ERROR_MESSAGE: "Oye, ¿cómo me pagan?",
				CHOICES: {
					FINANCE: "Finanzas",
					LEASE: "Arrendar",
					CASH: "Dinero",
				},
			},
			DOES_HAVE_TRADE_IN: {
				LABEL: "¿Este acuerdo tiene un intercambio?",
				ERROR_MESSAGE: "",
				CHOICES: {
					YES: "Sí",
					NO: "No",
				},
			},
		},
		CANT_FIND_NAME: "¿No encuentras el nombre? ¡Añádelo ahora!",
		DO_NOT_HAVE_DEAL_NUMBER: "No tengo un número de oferta",
		ODOMETER: "Cuentakilómetros",
		TEXT_ALERT_TO: "Las alertas de texto irán a",
		ADD_SALES_PROFESSIONAL: "Agregar un nuevo profesional de ventas",
		MAYBE_NOT: "Tal vez no.",
		ADD_ME: "¡Agrégame!",
	},
	welcome_page: {
		BY_CLICKING_NEXT:
			"*Al hacer clic en 'SIGUIENTE', permites que {{ DEALER_NAME }} comunique información importante sobre la propiedad a través de mensajes de texto.",
	},
	radio_presets_page: {
		PRESETS: "Ajustes preestablecidos",
		CHOOSE_BELOW: "Elegir #{{ number }} Abajo",
		CLICK_YOUR_STATION: "¡Haz clic en tu estación para agregarla!",
		CHOOSE_WISELY:
			"¡No puedes elegir tantos ajustes preestablecidos! Tendrás que eliminar uno antes de agregar otro. Elige sabiamente.",
	},
	zdzFsa: {
		DATE_AND_TIME: "Su primera cita de servicio es el {{date}} a las {{time}}.",
		INSTRUCTIONS:
			"¡Puede agregar al calendario de su elección eligiendo del menú desplegable a continuación!",
		NOTE: "Nota: Para agregar al calendario de Apple de su iPhone, esta página debe verse con Safari.",
		NOT_FOUND: "Detalles de la primera cita de servicio no encontrados",
		EVENT_NAME: "Primera cita de servicio en {{dealership}}",
		GREETING: "Hola {{name}}!",
	},
	AddToCalendarComponent: {
		PLACEHOLDER: "Seleccionar calendario",
	},
};

export default translationsES;
