import type { TranslationInterface } from "./interface.ts";

export const translationsNE: TranslationInterface = {
	index: { title: "रमकस इनडकसम सवगत छ" },

	accessories_page: {
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES_FOR: "मफ चहनछ, हमसग तपईक लग सहयकहर छनन",
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES: "मफ चहनछ, हमसग मल गरन सहयकहर छनन।",
	},
	buyer_page: {
		fields: {
			MIDDLE_NAME: { LABEL: "बचक नम", ERROR_MESSAGE: "" },
			SUFFIX: {
				LABEL: "उपशरषक",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "उपशरषक खजनहस...",
			},
			COUNTY: { LABEL: "जलल", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "जप कड", ERROR_MESSAGE: "" },
			CELL_PHONE: { LABEL: "मबइल फन", ERROR_MESSAGE: "" },
			WORK_PHONE: { LABEL: "कमक फन", ERROR_MESSAGE: "" },
			HOME_PHONE: { LABEL: "घरक फन", ERROR_MESSAGE: "" },
			EMAIL: { LABEL: "इमल", ERROR_MESSAGE: "" },
			"STREET_ADDRESS/PO_BOX": {
				LABEL: "सडकक ठगन/प. बकस",
				ERROR_MESSAGE: "",
			},
			"SUITE/APT": { LABEL: "सइट/अपरटमनट", ERROR_MESSAGE: "" },
			FIRST_NAME: { LABEL: "पहल नम", ERROR_MESSAGE: "ह! म मस भए!" },
			LAST_NAME: { LABEL: "थर", ERROR_MESSAGE: "ह! मलई नछडनहल!" },
			STREET: {
				LABEL: "",
				ERROR_MESSAGE: "मलई भरनहस। हम कन अपरतयशत भरमण गरदन",
			},
			CITY: {
				LABEL: "शहर",
				ERROR_MESSAGE: "तपईक समदयक परवक परदरशन गरनहस!",
			},
			STATE: { LABEL: "रजय", ERROR_MESSAGE: "परतनधतव गरनहस!" },
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "हम ५ अकक जपक खज गरदछ।",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "",
				ERROR_MESSAGE: "ZipDealलई तपईक जपकड चहय!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "कपय वध फन नमबर परवश गरनहस",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "",
				ERROR_MESSAGE: "ह तपई! म मलउन सकछ?",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "तपईलई {{- BIRTHDAY_MIN }} पछ क मत छनन परछ",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "तपईलई {{- BIRTHDAY_MAX }} अघ क मत छनन परछ",
			},
			CUSTOMER_BIRTHDAY: {
				LABEL: "जनम मत",
				ERROR_MESSAGE: "कपय वध मत परवश गरनहस",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "हमलई तपईक इमल चहय।",
			},
			CUSTOMER_EMAIL: { LABEL: "", ERROR_MESSAGE: "य एक इमल जसत दखदन।" },
		},

		KEEP_PHONE_HANDY:
			"(तपईक मबइल फन सग रखनहस, हमर बम परमणक तपईलई तपईक बम पषट गरनक लग फन गरन सकछ।)",
		TITLE_AND_REGISTRATION_INFORMATION:
			"तलक तपईक शरषक र दरत जनकर तपईक डरइभङ लइसनससग मल गरनपरछ।",
		IF_HAVE_COBUYER_OR_COSIGNER:
			"तपईसग यद क-कनक व क-सइनर छ भन, हम अरक सकरनम तयसक बरम सधनछ।",
		IS_THERE_COBUYER_FOR_PURCHASE: "यस कनमलम क-कनक व क-सइनर छ क छन?",
		INPUT_COBUYER_INFORMATION: "ह भन, कपय तनहरक पर नम र ठगन जनकर यह परवश गरनहस",
		IN_ADDITION_TO_YOU: "तपईक अलव, तपईक नय गडक दरतम अर कस पन नम गरन छ क छन?",
		HAVE_COSIGNER: "ह, म सग क-सइनर छ",
		JUST_ME: "हइन, य म म ह",
		NOT_COBUYER: "क-कनक/क-सइनर छन",
	},
	care_card_page: {
		CARE_CARD_TEXT:
			"तपईक सवर सधन तपईक जवनक सब करक कनदरय छ। य तपई र तपईक मलयवन समन लयउन र लयउन, कम, खलक करयकलप, वदयक, सकल नतय र जवनक अनय महततवपरण चरणहरम। तपईलई अब त आवशयक मरममतहर टढ नलगउनहस! {{ DEALER_NAME }} तपईक जवन रकन नदनक लग तपईलई मददत गरन सकछ।",
		INQUIRE_TODAY: "तपईक वकर व वतत पशवरसग आज सधनहस!",
	},

	collision_center_page: {
		AFTER_CAR_ACCIDENT: "कर दरघटन पछ तपईल गरनपरन १० कर",
		WE_WILL_TEXT_YOU:
			"हम तपईलई समपरक जनकर र य १० महततवपरण कदमहर पठउनछ तक तपईल य आफन फनम सरकषत रखन सकनहनछ!",
		"RULE#1": "#१ - सबलई ठक छ भनर पकक गरनहस र चट परयउन भए ९११ बलउनहस",
		"RULE#2": "#२ - दशयलई सरकषत बनउन आफ र अरलई सरकषत रखनहस",
		"RULE#3": "#३ - परहरलई बलउनहस, चटक भग व दषक परवह नगर",
		"RULE#4": "#४ - घटन भएक बल तपईक समरटफन परयग गरर ववरण डकयमनट गरनहस",
		"RULE#5": "#५ - सब पकषसग जनकर अदल-बदल गरनहस",
		"RULE#6": "#६ - परहर आएपछ दरघटन रपरट गरनहस",
		"RULE#7": "#७ - सहभग भएक सब गडहर र दशयक तसवरहर लनहस",
		"RULE#8": "#८ - तपईक बम कमपन र/व एजनटलई बलउनहस",
		"RULE#9": "#९ - तपईक सथनय दख-बचर सवधम जनहस",
		"RULE#10": "#१० - दरघटन सबनध सब डकयमनटसनक फइल बनउनहस",
	},

	common: {
		YES: "ह",
		NO: "हइन",
		BACK: "पछड",
		NEXT: "अरक",
		NO_THANK_YOU: "हइन, धनयवद",
		"YES!": "ह!",
		SCROLL_TO_REVEAL_MORE: "थप परकट गरन सकरल गरनहस",
		PLEASE_SCROLL: "कपय जर रखन सकरल गरनहस",
	},

	congratulation_page: {
		SALES_PROFESSIONAL_WAS_NOTIFIED:
			"तपईक वकर पशवरलई सचन परयइएक छ र तनहर तपईसग चड न हनछन!",
		RELAX_AND_ENJOY_VIDEOS: "रमरसग वशरम गरनहस र कह भडयहर हरनहस।",
		NOT_ALL_APPLICABLE_TO_ALL_MODELS: "सब वशषतहर सब मडलहरम लग हनन",
	},

	connected_car_page: {
		NO_CONNECTED_CAR_APP:
			"मफ गरनहस, हमसग {{vehicleMake}} गडहरक लग कनकटड कर एपप छन।",
	},

	feature_focus_page: {
		FEEL_FREE_TO_CLICK_TO_HIGHLIGHT:
			"तपईक अनतम वहन परवधनम तपईल थह पउन चहनहनछ भन तलक वहन जनकर शरणहरम कन पन कलक गरर हइलइट गरन सवतनतर महसस गरनहस <span class='font-bold'> {{ vehicleYear }} {{ vehicleMake}} {{ vehicleModel }} </span> डलभर",
	},

	fsa_page: {
		SCHEDULE_FSA: "तपईक पहल सव अपइनटमनटक तलक बनउनहस",
		WHAT_TIME_WORKS_BEST: "कन समय रमर लगछ?",
		WORRIED_ABOUT_COMMITMENT:
			"कमटमनट {{ timeToAppointment }} महनसमम बनउन चनतत? हइन! तपई मत नजकएक बल आफन सवध अनसर मत र समय परवरतन गरन सकनहनछ।",
		SELECTED_DATE_AVAILABILITY:
			"चयन गरएक मत उपलबधत: {{ engagedCapacity }}/{{ capacity }} सलट लइएक छ।",
		SELECTED_FSA_FOR: "तपईल तपईक पहल सव अपइनटमनटक चयन गरनभएक छ",
		CHANGE_DATE_OR_TIME: "म मत व समय परवरतन गरन चहनछ",
		AT: "म",
		SUN: "आइत",
		MON: "सम",
		TUE: "मगल",
		WED: "बध",
		THU: "बह",
		FRI: "शकर",
		SAT: "शन",
		JANUARY: "जनवर",
		FEBRUARY: "फबरअर",
		MARCH: "मरच",
		APRIL: "एपरल",
		MAY: "म",
		JUNE: "जन",
		JULY: "जलई",
		AUGUST: "अगषट",
		SEPTEMBER: "सपटमबर",
		OCTOBER: "अकटबर",
		NOVEMBER: "नभमबर",
		DECEMBER: "डसमबर",
	},
	insurance_page: {
		fields: {
			INSURANCE_STATUS: {
				LABEL: "कपय तपईक बम सथतलई सरवततम रपम परकट गरन चयन गरनहस",
				ERROR_MESSAGE: "",
				CHOICES: {
					I_HAVE_INSURANCE: "म सग बम छ",
					I_AM_EXISTING_CUSTOMER:
						"म {{ INHOUSE_AGENCY_NAME }} सग पहल दख गरहक छ र म य गड लई मर अवसथत पलसम थप/बदल गरन चहनछ।",
					I_DO_NOT_HAVE_INSURANCE_SETUP: "मर बम सटअप छन",
				},
			},
			NAME_OF_INSURED: { LABEL: "बमतक नम", ERROR_MESSAGE: "" },
			POLICY_NUMBER: { LABEL: "पलस नमबर", ERROR_MESSAGE: "" },
			POLICY_START_DATE: { LABEL: "पलस सर मत", ERROR_MESSAGE: "" },
			POLICY_END_DATE: { LABEL: "पलस समपत मत", ERROR_MESSAGE: "" },
			EMAIL: { LABEL: "इमल", ERROR_MESSAGE: "" },
			CITY: { LABEL: "शहर", ERROR_MESSAGE: "" },
			STATE: { LABEL: "रजय", ERROR_MESSAGE: "" },
			COUNTY: { LABEL: "जलल", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "जप कड", ERROR_MESSAGE: "" },
			BUYER_ADDRESS: { LABEL: "कनक ठगन", ERROR_MESSAGE: "" },
			BUYER_PHONE_NUMBER: { LABEL: "कनक फन नमबर", ERROR_MESSAGE: "" },
			INSURER: {
				LABEL: "{{ INHOUSE_AGENCY_NAME }} बम परदत",
				ERROR_MESSAGE: "",
			},
			INSURANCE_CARRIER: {
				LABEL: "बम कयरयर",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "कयरयर चयन गरनहस...",
			},
			CARRIER_ADDRESS: { LABEL: "कयरयर ठगन", ERROR_MESSAGE: "" },
			AGENT_NAME: {
				LABEL: "एजनटक पहल/अनतम नम (कन एजनट छन भन, 'डयरकट' टइप गरनहस)",
				ERROR_MESSAGE: "",
			},
			AGENT_PHONE_NUMBER: { LABEL: "एजनट फन नमबर", ERROR_MESSAGE: "" },
			EMPTY_ERROR_MESSAGE: " ",
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "कपय वध फन नमबर परवषट गरनहस",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "तपईल {{- BIRTHDAY_MIN }} पछ क मत चयन गरन परछ",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "तपईल {{- BIRTHDAY_MAX }} अघ क मत चयन गरन परछ",
			},
			POLICY_START_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "तपईल {{- POLICY_START_MIN }} पछ क मत चयन गरन परछ",
			},
			POLICY_START_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "तपईल {{- POLICY_START_MAX }} अघ क मत चयन गरन परछ",
			},
			POLICY_EXPIRATION_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "तपईल {{- POLICY_EXPIRATION_MIN }} पछ क मत चयन गरन परछ",
			},
			POLICY_EXPIRATION_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "तपईल {{- POLICY_EXPIRATION_MAX }} अघ क मत चयन गरन परछ",
			},
			ENTER_VALID_DATE: {
				LABEL: "",
				ERROR_MESSAGE: "कपय वध मत परवषट गरनहस",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "हमलई तपईक इमल चहनछ।",
			},
			CUSTOMER_EMAIL: { LABEL: "", ERROR_MESSAGE: "य एक इमल जसत दखदन।" },
		},
		I_DO_NOT_SEE_MY_CARRIER: "म मर कयरयर दखदन",
		POLICY_INFORMATION: "पलस जनकर",
		BUYER_INFORMATION: "कनक जनकर",
		CARRIER_INFORMATION: "कयरयर जनकर",
		AGENT_INFORMATION: "एजनट जनकर",
	},
	referral_page: {
		NAME_1: "सफरस #1 नम",
		CELL_PHONE: "मबइल नमबर",
		EMAIL: "इमल",
		NAME_2: "सफरस #2 नम",
		NAME_3: "सफरस #3 नम",
	},
	review_page: {
		YOUR_FEEDBACK_IMPORTANT: "तपईक परतकरय हमर लग महततवपरण छ!",
		HOW_YOU_RATE_US:
			"{{ dealershipName }} म तपईक अनभव लगक कत रट गरनहनछ, अहलसमम?",
		PLEASE_RATE_US: "कपय हमलई १ - ५ तर दख रट गरनहस",
		WE_ARE_DISAPPOINTED:
			"हमर अनभवल तपईक अपकष पर गरन। हम तपईक अनभव रमर बनउन हमल क गरन सकथय भनर सनन चहनछ।",
		WE_ARE_SORRY:
			"हम तपईक अपकष अहलसमम पर गरन पएन। यद तपई चहनहनछ भन, हम तपईक सचम हम कह सधर गरन सकछ भनर शयर गरन अनरध गरछ।",
		WE_WILL_TEXT_YOU: "यद तयस भए, हम तपईलई य सजल बनउनक लग एक लक पठउनछ!",
		SHARE_YOUR_EXPERIENCE:
			"क तपई एक पलक लग तपईक अनभव {{name}} समकष मरफत सझ गरन सकनहनछ?",
		WE_APPRECIATE_REVIEW: "हम समकष परशस गरछ!",
		REVIEW_LINK_WAS_TEXTED_TO_YOU:
			"{{name}} समकष लक तपईलई पठइएक छ। हम तपईक अनभव सझ गरकम धर परशस गरछ!",
		CONTINUE_ON: "जर रखनहस",
		LET_US_KNOW: "हमलई थह परनहस क हम रमर गरन सकछ",
		COMMENTS: "परतकरय...",
		CHANGE_MY_RATING: "मर रटग परवरतन गरनहस",
		NO_COMMENT: "कन परतकरय छन।",
		SEND_MY_FEEDBACK: "मर परतकरय पठउनहस",
	},

	sales_page: {
		fields: {
			DMS_OMNI_NUMBER: {
				LABEL: "{{usesDealNumber}}Stock # व VIN # परवषट गरनहस",
				ERROR_MESSAGE: "खज गरन एक नमबर परवषट गरनहस।",
				BUTTON: "लड",
			},
			DMS_OMNI_NUMBER_RV: {
				LABEL: "एउट समझत # परवषट गरनहस",
				ERROR_MESSAGE: "खज गरन नमबर परवषट गरनहस।",
				BUTTON: "लड",
			},
			DEAL_NUMBER: { LABEL: "डल नमबर", ERROR_MESSAGE: "" },
			CHOOSE_DEAL_TYPE: { LABEL: "डल परकर चयन गरनहस", ERROR_MESSAGE: "" },
			STOCK_NUMBER: {
				LABEL: "सटक नमबर",
				ERROR_MESSAGE: "क बचएक छ?",
				BUTTON: "Inv बट लड गरनहस",
			},
			VEHICLE_CLASS: {
				LABEL: "वहन परकर",
				ERROR_MESSAGE: "वहन परकर क ह?",
				PLACEHOLDER: "वहन ककष खजनहस...",
			},
			SALES_MANAGER: {
				LABEL: "वपणन वयकत #",
				PLACEHOLDER: "वपणन पशवरक लग खजनहस....",
			},
			SALES_PROFESSIONAL_FIRST_NAME: {
				LABEL: "वपणन पशवर पहल नम",
				ERROR_MESSAGE: "",
			},
			SALES_PROFESSIONAL_LAST_NAME: {
				LABEL: "वपणन पशवर थर",
				ERROR_MESSAGE: "",
			},
			CELL_PHONE_FOR_TEXT_ALERTS: {
				LABEL: "टकसट अलरटक लग मबइल फन",
				ERROR_MESSAGE: "",
			},
			FIRST_NAME: { LABEL: "", ERROR_MESSAGE: "पहल नम आवशयक छ" },
			LAST_NAME: { LABEL: "", ERROR_MESSAGE: "थर आवशयक छ" },
			DMS_DEAL_NUMBER: {
				LABEL: "DMS डल नमबर",
				ERROR_MESSAGE: "मलई बरसन हदन!",
				BUTTON: "DMS बट लड गरनहस",
			},
			DESK_MANAGER: {
				LABEL: "डसक परबनधक",
				ERROR_MESSAGE: "य सचलनक जममवर क ह?!",
				PLACEHOLDER: "वपणन परबनधक खजनहस...",
			},
			TRADE_IN_QUESTIONNAIRE: {
				LABEL: "",
				ERROR_MESSAGE: "तनहर कह वयपर गरदछन?",
			},
			SALES_CONSULTANT_1: {
				LABEL: "",
				ERROR_MESSAGE: "मलई खल छडर तपईक कमशन दन गरनहस।",
			},
			VIN: { LABEL: "वहन VIN #", ERROR_MESSAGE: "मर जनटक DNA पच गरनहस।" },
			VIN_MIN: { LABEL: "", ERROR_MESSAGE: "य VIN क लग धर छट छ।" },
			VIN_MAX: { LABEL: "", ERROR_MESSAGE: "य VIN हन गरर धर ठल छ।" },
			YEAR: {
				LABEL: "वरष",
				ERROR_MESSAGE: "मर जनमदन ककम कत ममबटहर छन?",
				PLACEHOLDER: "एक वरष चयन गरनहस...",
			},
			MAKE: {
				LABEL: "मक",
				ERROR_MESSAGE: "म मलई बनए?",
				PLACEHOLDER: "एक मक चयन गरनहस...",
				LOADING: "मक लड गरद...",
			},
			MODEL: {
				LABEL: "मडल",
				ERROR_MESSAGE: "म मलई लटठ परन हदन!",
				PLACEHOLDER: "एक मडल चयन गरनहस...",
				LOADING: "मडलहर लड गरद...",
			},
			TRIM: {
				LABEL: "तरम",
				ERROR_MESSAGE: "मर वशषत क ह भनर बरसन हदन।",
				PLACEHOLDER: "एक तरम चयन गरनहस...",
				MODEL_FIRST: "पहल एक मडल चयन गरनहस।",
				LOADING: "तरमहर लड गरद...",
			},
			DOES_HAVE_TRADE_IN: {
				LABEL: "यस डलम वयपर-इन छ?",
				ERROR_MESSAGE: "",
				CHOICES: {
					YES: "ह",
					NO: "हइन",
				},
			},
			DEALERSHIP_CHOICE: {
				LABEL: "",
				ERROR_MESSAGE: "हम य डल लग कसक गरदछ?",
			},
			CONNECTED_CAR_PHONE_APP: {
				LABEL: "कनकटड कर फन एप",
				ERROR_MESSAGE: "ह, म म समरट कर ह?",
				CHOICES: {
					YES: "ह",
					NO: "हइन",
				},
			},
			CONNECTED_PHONE_APP: {
				LABEL: "कनकटड फन एप",
				ERROR_MESSAGE: "म म फन एप छ?",
				CHOICES: {
					YES: "ह",
					NO: "हइन",
				},
			},
			DIGITAL_DELIVERY_TYPE: {
				LABEL: "",
				ERROR_MESSAGE: "तपई कन तरक ल सचन पउन चहनहनछ?",
			},
			CUSTOMER_FIRST_NAME: {
				LABEL: "गरहक पहल नम",
				ERROR_MESSAGE: "ओह! तपईल मलई छटक!",
			},
			CUSTOMER_LAST_NAME: {
				LABEL: "गरहक थर",
				ERROR_MESSAGE: "व! मलई बरसन हदन!",
			},
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "हम ५ अकक जप कडक खज गरदछ।",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "गरहक जप कड",
				ERROR_MESSAGE: "ZipDealलई गरहकक जपकड चहय!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "कपय एक मनय फन नमबर परवषट गरनहस",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "गरहक मबइल फन",
				ERROR_MESSAGE: "हमलई उनहरक अकहर चहय!",
			},
			CUSTOMER_EMAIL: { LABEL: "", ERROR_MESSAGE: "उनहरक इमल क ह?" },
			FINANCE_TYPE: {
				LABEL: "वतत परकर",
				ERROR_MESSAGE: "ह, उनहर मलई कसर तरन छन?",
				CHOICES: {
					FINANCE: "वतत",
					LEASE: "लज",
					CASH: "नगद",
				},
			},
		},
		CANT_FIND_NAME: "नम फल परनहनन? अहल थपनहस!",
		DO_NOT_HAVE_DEAL_NUMBER: "म सग डल नमबर छन",
		TEXT_ALERT_TO: "टकसट अलरट जनछ",
		ODOMETER: "ओडमटर",
		ADD_SALES_PROFESSIONAL: "नय वपणन पशवर थपनहस",
		MAYBE_NOT: "हइन शयद।",
		ADD_ME: "मलई थपनहस!",
	},
	welcome_page: {
		BY_CLICKING_NEXT:
			"*अगड 'NEXT' थचद तपईल {{ DEALER_NAME }} लई महततवपरण सवमतव जनकर टकसटम बलउन अनमत दनहनछ।",
	},
	radio_presets_page: {
		PRESETS: "परसटहर",
		CHOOSE_BELOW: "तलक #{{ number }} छननहस",
		CLICK_YOUR_STATION: "तपईक सटशन थचर थपनहस!",
		CHOOSE_WISELY:
			"तपई तयत धर परसट छनन सकनहनन! अरक थपन अघ तपईलई एउट हटउन परनछ। बदधमनल छननहस।",
	},
	zdzFsa: {
		DATE_AND_TIME: "तपईक पहल सव अपइनटमनट {{ date }} म {{time}} ह।",
		INSTRUCTIONS: "तलक डरपडउनबट छनर तपईक रजएक कयलणडरम थपन सकनहनछ!",
		NOTE: "नट: तपईक iPhone क Apple कयलणडरम थपनक लग य पजलई Safari सग हरन परछ।",
		NOT_FOUND: "पहल सव अपइनटमनटक ववरण फल परन",
		GREETING: "नमसकर, {{name}}!",
		EVENT_NAME: "{{dealership}} म पहल सव अपइनटमनट",
	},
	AddToCalendarComponent: {
		PLACEHOLDER: "कयलणडर छननहस",
	},
};

export default translationsNE;
