import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { StrictMode, startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { getInitialNamespaces } from "remix-i18next/client";
import { measureDownloadSpeed } from "~/measureDownloadSpeed.tsx";
import { translationsEN } from "~/translations/en.ts";
import { translationsEN_US } from "~/translations/enUS.ts";
import { translationsES } from "~/translations/es.ts";
import { translationsFR_CA } from "~/translations/frCA.ts";
import { translationsNE } from "~/translations/ne.ts";
import { translationsUA } from "~/translations/ua.ts";
import i18n from "~common/i18n.ts";

// get the domain of the window
const domain = window.location.hostname.split(".")[1];

if (import.meta.env.MODE !== "development") {
	console.log("Sentry.init");

	let e = "devs";

	switch (domain) {
		case "zipdeal":
			e = "eastern";
			break;
		case "zd-int":
			e = "integration";
			break;
		default:
			e = "devs";
			break;
	}

	Sentry.init({
		dsn: "https://c29d0414bfbd415bae911491a2fd6624@o553701.ingest.sentry.io/4503909411520512", // remix-server dsn
		// dsn: `https://8163bba138f54a63b41369f5b5811d09@o553701.ingest.sentry.io/5681355`, // zipdeal-remix-client dsn
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
		autoInstrumentRemix: true,
		environment: `${e}-remix-client`,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.

		integrations: [
			Sentry.browserTracingIntegration({
				useEffect,
				useLocation,
				useMatches,
			}),
			// Replay is only available in the client
			// Sentry.replayIntegration(),
		],
	});
}

measureDownloadSpeed(false);

startTransition(() => {
	i18next
		.use(initReactI18next) // Tell i18next to use the react-i18next plugin
		.use(LanguageDetector) // Setup a client-side language detector
		.init({
			supportedLngs: i18n.supportedLngs,
			fallbackLng: i18n.fallbackLng,
			react: { useSuspense: false },
			ns: getInitialNamespaces(),
			detection: { order: ["querystring", "htmlTag"], caches: [] },
			resources: {
				"en-US": { translation: translationsEN_US },
				en: { translation: translationsEN },
				es: { translation: translationsES },
				fr: { translation: translationsFR_CA },
				ua: { translation: translationsUA },
				ne: { translation: translationsNE },
			},
		});
	hydrateRoot(
		document,
		// <I18nextProvider i18n={i18next}>
		//   <RemixBrowser />
		// </I18nextProvider>,
		<StrictMode>
			<I18nextProvider i18n={i18next}>
				<RemixBrowser />
			</I18nextProvider>
		</StrictMode>,
	);
});
