import type { TranslationInterface } from "./interface.ts";

export const translationsFR_CA: TranslationInterface = {
	index: { title: "Bienvenue à l'index Remix" },

	accessories_page: {
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES_FOR:
			"Désolé, nous n'avons pas d'accessoires pour le",
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES:
			"Désolé, nous n'avons pas d'accessoires correspondants.",
	},

	buyer_page: {
		fields: {
			MIDDLE_NAME: { LABEL: "Deuxième prénom", ERROR_MESSAGE: "" },
			SUFFIX: {
				LABEL: "Suffixe",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "Chercher un suffixe...",
			},
			COUNTY: { LABEL: "Comté", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "Code postal", ERROR_MESSAGE: "" },
			CELL_PHONE: { LABEL: "Téléphone cellulaire", ERROR_MESSAGE: "" },
			WORK_PHONE: { LABEL: "Téléphone au travail", ERROR_MESSAGE: "" },
			HOME_PHONE: { LABEL: "Téléphone à la maison", ERROR_MESSAGE: "" },
			EMAIL: { LABEL: "Courriel", ERROR_MESSAGE: "" },
			"STREET_ADDRESS/PO_BOX": {
				LABEL: "Adresse/Boîte postale",
				ERROR_MESSAGE: "",
			},
			"SUITE/APT": { LABEL: "Appartement", ERROR_MESSAGE: "" },
			FIRST_NAME: {
				LABEL: "Prénom",
				ERROR_MESSAGE: "Oups ! Vous m'avez manqué !",
			},
			LAST_NAME: { LABEL: "Nom", ERROR_MESSAGE: "Oups ! Ne m'oubliez pas !" },
			STREET: {
				LABEL: "",
				ERROR_MESSAGE: "Remplissez-moi. Nous promettons pas de visite surprise",
			},
			CITY: {
				LABEL: "Ville",
				ERROR_MESSAGE: "Montrez votre fierté communautaire !",
			},
			STATE: { LABEL: "Province", ERROR_MESSAGE: "Représentez !" },
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Nous cherchons un code postal à 5 chiffres.",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "",
				ERROR_MESSAGE: "ZipDeal a besoin de votre code postal !",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Veuillez entrer un numéro de téléphone valide",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "",
				ERROR_MESSAGE: "Hé toi ! Puis-je avoir vos chiffres ?",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Vous devez sélectionner une date après {{- BIRTHDAY_MIN }}",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Vous devez sélectionner une date avant {{- BIRTHDAY_MAX }}",
			},
			CUSTOMER_BIRTHDAY: {
				LABEL: "Date de naissance",
				ERROR_MESSAGE: "Veuillez entrer une date valide",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "Nous avons besoin de votre courriel.",
			},
			CUSTOMER_EMAIL: {
				LABEL: "",
				ERROR_MESSAGE: "Ça ne ressemble pas à un courriel.",
			},
		},

		KEEP_PHONE_HANDY:
			"(Gardez votre téléphone cellulaire à portée de main, notre vérificateur d'assurance pourrait vous appeler pour confirmer votre assurance)",
		TITLE_AND_REGISTRATION_INFORMATION:
			"Les informations ci-dessous concernant votre titre et votre enregistrement doivent correspondre à votre permis de conduire.",
		IF_HAVE_COBUYER_OR_COSIGNER:
			"Si vous avez un co-acheteur ou un cosignataire, nous vous poserons la question à l'écran suivant.",
		IS_THERE_COBUYER_FOR_PURCHASE:
			"Y a-t-il un co-acheteur ou un cosignataire pour cet achat ?",
		INPUT_COBUYER_INFORMATION:
			"Si oui, veuillez saisir ici leurs nom et adresse complets",
		IN_ADDITION_TO_YOU:
			"En plus de vous, quelqu'un d'autre sera-t-il nommé sur l'enregistrement de votre nouveau véhicule ?",
		HAVE_COSIGNER: "Oui, j'ai un cosignataire",
		JUST_ME: "Non, c'est juste moi",
		NOT_COBUYER: "Il n'y a pas de co-acheteur/cosignataire",
	},

	care_card_page: {
		CARE_CARD_TEXT:
			"Votre véhicule est au centre de tout dans votre vie. Il vous transporte, ainsi que vos objets précieux, vers et depuis le travail, les événements sportifs, les vacances, les bals de finissants et les autres étapes importantes de la vie. Il n'est plus nécessaire de repousser ces réparations nécessaires ! {{ DEALER_NAME }} peut vous aider à vous assurer que votre vie ne s'arrête pas avec leur {{ CARE_CARD_NAME }}",
		INQUIRE_TODAY:
			"Renseignez-vous aujourd'hui auprès de votre professionnel de la vente ou de la finance !",
	},

	common: {
		YES: "Oui",
		NO: "Non",
		BACK: "Retour",
		NEXT: "Suivant",
		NO_THANK_YOU: "Non, Merci",
		"YES!": "Oui!",
		SCROLL_TO_REVEAL_MORE: "faites défiler pour en révéler plus",
		PLEASE_SCROLL: "Veuillez faire défiler pour continuer",
	},
	congratulation_page: {
		SALES_PROFESSIONAL_WAS_NOTIFIED:
			"Votre professionnel de vente a été notifié et sera avec vous sous peu!",
		RELAX_AND_ENJOY_VIDEOS: "Détendez-vous et regardez quelques vidéos.",
		NOT_ALL_APPLICABLE_TO_ALL_MODELS:
			"Toutes les fonctionnalités ne s'appliquent pas à tous les modèles",
	},
	connected_car_page: {
		NO_CONNECTED_CAR_APP:
			"Désolé, nous n'avons pas d'application de voiture connectée pour les véhicules {{vehicleMake}}.",
	},
	collision_center_page: {
		AFTER_CAR_ACCIDENT: "Les 10 choses à faire après un accident de voiture",
		WE_WILL_TEXT_YOU:
			"Nous vous enverrons par SMS les informations de contact et ces 10 étapes importantes afin que vous les ayez de manière sûre et sécurisée dans votre téléphone si besoin!",
		"RULE#1":
			"#1 – Assurez-vous que tout le monde va bien et appelez le 911 si les blessures le justifient",
		"RULE#2":
			"#2 – Protégez-vous et protégez les autres en sécurisant la scène",
		"RULE#3":
			"#3 – Appelez la police, indépendamment des blessures ou des fautes",
		"RULE#4":
			"#4 – Documentez les détails de l'événement tant qu'ils sont frais – utilisez votre smartphone",
		"RULE#5": "#5 – Échangez les informations avec toutes les parties",
		"RULE#6": "#6 – Signalez l'accident dès que la police arrive",
		"RULE#7":
			"#7 – Prenez des photos de tous les véhicules impliqués et de la scène",
		"RULE#8": "#8 – Appelez votre compagnie d'assurance et/ou agent",
		"RULE#9": "#9 – Faites-vous examiner dans un centre de soins local",
		"RULE#10":
			"#10 – Gardez un dossier de toute la documentation liée à l'accident",
	},
	feature_focus_page: {
		FEEL_FREE_TO_CLICK_TO_HIGHLIGHT:
			"N'hésitez pas à cliquer pour mettre en évidence l'une des catégories d'informations sur le véhicule ci-dessous que vous aimeriez en savoir plus lors de votre orientation finale du véhicule pour votre livraison <span class='font-bold'> {{ vehicleYear }} {{ vehicleMake}} {{ vehicleModel }} </span>",
	},
	fsa_page: {
		SCHEDULE_FSA: "Planifiez votre premier rendez-vous de service",
		WHAT_TIME_WORKS_BEST: "Quelle heure vous convient le mieux?",
		WORRIED_ABOUT_COMMITMENT:
			"Inquiet de prendre un engagement {{ timeToAppointment }} mois à l'avance? Ne le soyez pas! Vous pourrez reprogrammer à votre guise à mesure que la date approche.",
		SELECTED_DATE_AVAILABILITY:
			"Disponibilité de la date sélectionnée: {{ engagedCapacity }}/{{ capacity }} créneaux pris.",
		SELECTED_FSA_FOR:
			"Vous avez choisi votre premier rendez-vous de service pour",
		CHANGE_DATE_OR_TIME: "Je veux changer la date ou l'heure",
		AT: "à",
		SUN: "DIM",
		MON: "LUN",
		TUE: "MAR",
		WED: "MER",
		THU: "JEU",
		FRI: "VEN",
		SAT: "SAM",
		JANUARY: "Janvier",
		FEBRUARY: "Février",
		MARCH: "Mars",
		APRIL: "Avril",
		MAY: "Mai",
		JUNE: "Juin",
		JULY: "Juillet",
		AUGUST: "Août",
		SEPTEMBER: "Septembre",
		OCTOBER: "Octobre",
		NOVEMBER: "Novembre",
		DECEMBER: "Décembre",
	},

	insurance_page: {
		fields: {
			INSURANCE_STATUS: {
				LABEL:
					"Veuillez sélectionner le statut d'assurance qui vous correspond le mieux",
				ERROR_MESSAGE: "",
				CHOICES: {
					I_HAVE_INSURANCE: "J'ai une assurance",
					I_AM_EXISTING_CUSTOMER:
						"Je suis un client existant avec {{ INHOUSE_AGENCY_NAME}} et je souhaite remplacer/ajouter ce véhicule à ma police existante.",
					I_DO_NOT_HAVE_INSURANCE_SETUP: "Je n'ai pas d'assurance",
				},
			},
			NAME_OF_INSURED: { LABEL: "Nom de l'assuré", ERROR_MESSAGE: "" },
			POLICY_NUMBER: { LABEL: "Numéro de police", ERROR_MESSAGE: "" },
			POLICY_START_DATE: {
				LABEL: "Date de début de la police",
				ERROR_MESSAGE: "",
			},
			POLICY_END_DATE: { LABEL: "Date de fin de la police", ERROR_MESSAGE: "" },
			EMAIL: { LABEL: "Email", ERROR_MESSAGE: "" },
			CITY: { LABEL: "Ville", ERROR_MESSAGE: "" },
			STATE: { LABEL: "Province", ERROR_MESSAGE: "" },
			COUNTY: { LABEL: "Comté", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "Code postal", ERROR_MESSAGE: "" },
			BUYER_ADDRESS: { LABEL: "Adresse de l'acheteur", ERROR_MESSAGE: "" },
			BUYER_PHONE_NUMBER: {
				LABEL: "Numéro de téléphone de l'acheteur",
				ERROR_MESSAGE: "",
			},
			INSURER: {
				LABEL: "Assureur {{ INHOUSE_AGENCY_NAME }}",
				ERROR_MESSAGE: "",
			},
			INSURANCE_CARRIER: {
				LABEL: "Compagnie d'assurance",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "Sélectionnez une compagnie...",
			},
			CARRIER_ADDRESS: { LABEL: "Adresse de la compagnie", ERROR_MESSAGE: "" },
			AGENT_NAME: {
				LABEL:
					"Prénom/Nom de l'agent (si pas d'agent, veuillez taper 'DIRECT')",
				ERROR_MESSAGE: "",
			},
			AGENT_PHONE_NUMBER: {
				LABEL: "Numéro de téléphone de l'agent",
				ERROR_MESSAGE: "",
			},
			EMPTY_ERROR_MESSAGE: " ",
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Veuillez entrer un numéro de téléphone valide",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Vous devez sélectionner une date après {{- BIRTHDAY_MIN }}",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Vous devez sélectionner une date avant {{- BIRTHDAY_MAX }}",
			},
			POLICY_START_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Vous devez sélectionner une date après {{- POLICY_START_MIN }}",
			},
			POLICY_START_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Vous devez sélectionner une date avant {{- POLICY_START_MAX }}",
			},
			POLICY_EXPIRATION_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"Vous devez sélectionner une date après {{- POLICY_EXPIRATION_MIN }}",
			},
			POLICY_EXPIRATION_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"Vous devez sélectionner une date avant {{- POLICY_EXPIRATION_MAX }}",
			},
			ENTER_VALID_DATE: {
				LABEL: "",
				ERROR_MESSAGE: "Veuillez entrer une date valide",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "Nous avons besoin de votre email.",
			},
			CUSTOMER_EMAIL: {
				LABEL: "",
				ERROR_MESSAGE: "Cela ne ressemble pas à un email.",
			},
		},
		I_DO_NOT_SEE_MY_CARRIER: "Je ne vois pas ma compagnie",
		POLICY_INFORMATION: "Informations sur la police",
		BUYER_INFORMATION: "Informations sur l'acheteur",
		CARRIER_INFORMATION: "Informations sur la compagnie",
		AGENT_INFORMATION: "Informations sur l'agent",
	},
	referral_page: {
		NAME_1: "Nom de Référence #1",
		CELL_PHONE: "Numéro de Portable",
		EMAIL: "Courriel",
		NAME_2: "Nom de Référence #2",
		NAME_3: "Nom de Référence #3",
	},
	review_page: {
		YOUR_FEEDBACK_IMPORTANT: "Votre avis nous est important!",
		HOW_YOU_RATE_US:
			"Comment évalueriez-vous votre expérience chez {{ dealershipName }}, jusqu'à présent?",
		PLEASE_RATE_US: "Veuillez nous noter de 1 à 5 étoiles",
		WE_ARE_DISAPPOINTED:
			"Nous sommes déçus que votre expérience n'ait pas répondu à vos attentes. Nous aimerions savoir ce que nous aurions pu faire pour améliorer votre expérience.",
		WE_ARE_SORRY:
			"Nous sommes désolés de ne pas avoir été à la hauteur de vos attentes jusqu'à présent. Si vous le souhaitez, nous apprécierions que vous partagiez vos réflexions sur ce que nous pouvons améliorer.",
		WE_WILL_TEXT_YOU:
			"Si oui, nous vous enverrons un lien par SMS pour faciliter les choses!",
		SHARE_YOUR_EXPERIENCE:
			"Vous serait-il possible de prendre un moment pour partager votre expérience à travers un avis {{name}}?",
		WE_APPRECIATE_REVIEW: "Nous apprécions l'avis!",
		REVIEW_LINK_WAS_TEXTED_TO_YOU:
			"Le lien pour l'avis {{name}} vous a été envoyé par SMS. Nous vous remercions grandement de partager votre expérience!",
		CONTINUE_ON: "Continuer",
		LET_US_KNOW: "Faites-nous savoir ce que nous pouvons améliorer",
		COMMENTS: "Commentaires...",
		CHANGE_MY_RATING: "Changer mon évaluation",
		NO_COMMENT: "Pas de commentaire.",
		SEND_MY_FEEDBACK: "Envoyer mes commentaires",
	},

	sales_page: {
		fields: {
			DMS_OMNI_NUMBER: {
				LABEL: "Entrez un {{usesDealNumber}} numéro de stock ou VIN #",
				ERROR_MESSAGE: "Entrez un numéro pour rechercher.",
				BUTTON: "Charger",
			},
			DEAL_NUMBER: { LABEL: "Numéro de l'affaire", ERROR_MESSAGE: "" },
			CHOOSE_DEAL_TYPE: {
				LABEL: "Choisissez le type d'affaire",
				ERROR_MESSAGE: "",
			},
			STOCK_NUMBER: {
				LABEL: "Numéro de stock",
				ERROR_MESSAGE: "Qu'est-ce qui est vendu?",
				BUTTON: "Charger depuis l'inv",
			},
			DMS_OMNI_NUMBER_RV: {
				LABEL: "Entrez numéro de l'affaire",
				ERROR_MESSAGE: "Entrez un numéro à rechercher.",
				BUTTON: "Charger",
			},
			VEHICLE_CLASS: {
				LABEL: "Type de véhicule",
				ERROR_MESSAGE: "Quel type de véhicule?",
				PLACEHOLDER: "Rechercher une classe de véhicule...",
			},
			SALES_MANAGER: {
				LABEL: "Numéro de vendeur",
				PLACEHOLDER: "Rechercher un professionnel de la vente...",
			},
			SALES_PROFESSIONAL_FIRST_NAME: {
				LABEL: "Prénom du professionnel de vente",
				ERROR_MESSAGE: "",
			},
			SALES_PROFESSIONAL_LAST_NAME: {
				LABEL: "Nom de famille du professionnel de vente",
				ERROR_MESSAGE: "",
			},
			CELL_PHONE_FOR_TEXT_ALERTS: {
				LABEL: "Téléphone cellulaire pour les alertes par texto",
				ERROR_MESSAGE: "",
			},
			FIRST_NAME: { LABEL: "", ERROR_MESSAGE: "Le prénom est requis" },
			LAST_NAME: { LABEL: "", ERROR_MESSAGE: "Le nom de famille est requis" },
			DMS_DEAL_NUMBER: {
				LABEL: "Numéro de l'affaire DMS",
				ERROR_MESSAGE: "Ne m'oubliez pas!",
				BUTTON: "Charger depuis DMS",
			},
			DESK_MANAGER: {
				LABEL: "Gestionnaire de bureau",
				ERROR_MESSAGE: "Qui est responsable de cette opération?!",
				PLACEHOLDER: "Rechercher un gestionnaire de vente...",
			},
			TRADE_IN_QUESTIONNAIRE: {
				LABEL: "",
				ERROR_MESSAGE: "Font-ils un échange?",
			},
			SALES_CONSULTANT_1: {
				LABEL: "",
				ERROR_MESSAGE:
					"Laissez-moi vide pour faire don de votre commission à une œuvre caritative.",
			},
			VIN: {
				LABEL: "Numéro VIN du véhicule",
				ERROR_MESSAGE: "Tapez mon ADN génétique s'il vous plaît.",
			},
			VIN_MIN: { LABEL: "", ERROR_MESSAGE: "C'est trop court pour un VIN." },
			VIN_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "C'est trop long pour être un VIN.",
			},
			YEAR: {
				LABEL: "Année",
				ERROR_MESSAGE: "Combien de bougies sur mon gâteau d'anniversaire?",
				PLACEHOLDER: "Sélectionnez une année...",
			},
			MAKE: {
				LABEL: "Marque",
				ERROR_MESSAGE: "Qui m'a fait?",
				PLACEHOLDER: "Sélectionnez une marque...",
				LOADING: "CHARGEMENT DES MARQUES...",
			},
			MODEL: {
				LABEL: "Modèle",
				ERROR_MESSAGE: "Ne me laissez pas pendre ici!",
				PLACEHOLDER: "Sélectionnez un modèle...",
				LOADING: "CHARGEMENT DES MODÈLES...",
			},
			TRIM: {
				LABEL: "Finition",
				ERROR_MESSAGE: "N'oubliez pas ce qui me rend spécial.",
				PLACEHOLDER: "Sélectionnez une finition...",
				MODEL_FIRST: "Sélectionnez un modèle d'abord.",
				LOADING: "CHARGEMENT DES FINITIONS...",
			},
			DOES_HAVE_TRADE_IN: {
				LABEL: "Cette affaire a-t-elle un échange?",
				ERROR_MESSAGE: "",
				CHOICES: {
					YES: "Oui",
					NO: "Non",
				},
			},
			DEALERSHIP_CHOICE: {
				LABEL: "",
				ERROR_MESSAGE: "Pour qui faisons-nous cette affaire?",
			},
			CONNECTED_CAR_PHONE_APP: {
				LABEL: "Application téléphonique de voiture connectée",
				ERROR_MESSAGE: "Hé, suis-je une voiture intelligente?",
				CHOICES: {
					YES: "Oui",
					NO: "Non",
				},
			},
			CONNECTED_PHONE_APP: {
				LABEL: "Application téléphonique connectée",
				ERROR_MESSAGE: "Ai-je une application téléphonique?",
				CHOICES: {
					YES: "Oui",
					NO: "Non",
				},
			},
			DIGITAL_DELIVERY_TYPE: {
				LABEL: "",
				ERROR_MESSAGE: "Comment souhaitez-vous être informé?",
			},
			CUSTOMER_FIRST_NAME: {
				LABEL: "Prénom du client",
				ERROR_MESSAGE: "Oups! Vous m'avez manqué!",
			},
			CUSTOMER_LAST_NAME: {
				LABEL: "Nom de famille du client",
				ERROR_MESSAGE: "Whoah! Ne m'oubliez pas!",
			},
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Nous recherchons un code postal à 5 chiffres.",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "Code postal du client",
				ERROR_MESSAGE: "ZipDeal a besoin du code postal du client!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Veuillez entrer un numéro de téléphone valide",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "Téléphone cellulaire du client",
				ERROR_MESSAGE: "Nous avons besoin de leurs chiffres!",
			},
			CUSTOMER_EMAIL: { LABEL: "", ERROR_MESSAGE: "Quel est leur courriel?" },
			FINANCE_TYPE: {
				LABEL: "Type de financement",
				ERROR_MESSAGE: "Hé, comment vont-ils me payer?",
				CHOICES: {
					FINANCE: "Financement",
					LEASE: "Location",
					CASH: "Comptant",
				},
			},
		},
		CANT_FIND_NAME: "Vous ne trouvez pas le nom? Ajoutez-le maintenant!",
		DO_NOT_HAVE_DEAL_NUMBER: "Je n'ai pas de numéro d'affaire",
		TEXT_ALERT_TO: "Les alertes par texto seront envoyées à",
		ODOMETER: "Odomètre",
		ADD_SALES_PROFESSIONAL: "Ajouter un nouveau professionnel de vente",
		MAYBE_NOT: "Peut-être pas.",
		ADD_ME: "Ajoutez-moi!",
	},

	welcome_page: {
		BY_CLICKING_NEXT:
			"*En cliquant sur 'SUIVANT', vous permettez à {{ DEALER_NAME }} de communiquer des informations importantes sur la propriété par texte.",
	},
	radio_presets_page: {
		PRESETS: "Préréglages",
		CHOOSE_BELOW: "Choisissez le #{{ number }} ci-dessous",
		CLICK_YOUR_STATION: "Cliquez sur votre station pour l'ajouter!",
		CHOOSE_WISELY:
			"Vous ne pouvez pas choisir autant de préréglages! Vous devrez en supprimer un avant d'en ajouter un autre. Choisissez judicieusement.",
	},
	zdzFsa: {
		DATE_AND_TIME:
			"Votre premier rendez-vous de service est prévu pour {{ date }} à {{time}}.",
		INSTRUCTIONS:
			"Vous pouvez ajouter au calendrier de votre choix en choisissant dans la liste déroulante ci-dessous!",
		NOTE: "Remarque: Pour ajouter à votre calendrier Apple sur votre iPhone, cette page doit être consultée avec Safari.",
		NOT_FOUND: "Les détails du premier rendez-vous de service introuvables",
		GREETING: "Salut, {{name}}!",
		EVENT_NAME: "Premier rendez-vous de service chez {{dealership}}",
	},
	AddToCalendarComponent: {
		PLACEHOLDER: "Sélectionner un calendrier",
	},
};

export default translationsFR_CA;
