import type { TranslationInterface } from "./interface.ts";

export const translationsEN: TranslationInterface = {
	index: { title: "Welcome to Remix index" },

	accessories_page: {
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES_FOR:
			"Sorry, we do not have accessories for the",
		SORRY_WE_DO_NOT_HAVE_ACCESSORIES:
			"Sorry, we do not have matching accessories.",
	},

	buyer_page: {
		fields: {
			MIDDLE_NAME: { LABEL: "Middle Name", ERROR_MESSAGE: "" },
			SUFFIX: {
				LABEL: "Suffix",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "Search a suffix...",
			},
			COUNTY: { LABEL: "County", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "Zip Code", ERROR_MESSAGE: "" },
			CELL_PHONE: { LABEL: "Cell Phone", ERROR_MESSAGE: "" },
			WORK_PHONE: { LABEL: "Work Phone", ERROR_MESSAGE: "" },
			HOME_PHONE: { LABEL: "Home Phone", ERROR_MESSAGE: "" },

			EMAIL: { LABEL: "Email", ERROR_MESSAGE: "" },
			"STREET_ADDRESS/PO_BOX": {
				LABEL: "Street Address/PO Box",
				ERROR_MESSAGE: "",
			},
			"SUITE/APT": { LABEL: "Suite/Apt", ERROR_MESSAGE: "" },
			FIRST_NAME: {
				LABEL: "First Name",
				ERROR_MESSAGE: "Ooops!  You missed me!",
			},
			LAST_NAME: {
				LABEL: "Last Name",
				ERROR_MESSAGE: "Ooops!  Don't forget me!",
			},
			STREET: {
				LABEL: "",
				ERROR_MESSAGE: "Fill me out.  We promise no surprise visits",
			},
			CITY: { LABEL: "City", ERROR_MESSAGE: "Show your community pride!" },
			STATE: { LABEL: "State", ERROR_MESSAGE: "Represent!" },
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "We are looking for a 5 digit Zip.",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "",
				ERROR_MESSAGE: "ZipDeal needs your ZipCode!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Please enter a valid phone number",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "",
				ERROR_MESSAGE: "Hey you! Can I get your digits?",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "You need to select a date after {{- BIRTHDAY_MIN }}",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "You need to select a date before {{- BIRTHDAY_MAX }}",
			},
			CUSTOMER_BIRTHDAY: {
				LABEL: "Date of Birth",
				ERROR_MESSAGE: "Please enter a valid date",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "We need your email.",
			},
			CUSTOMER_EMAIL: {
				LABEL: "",
				ERROR_MESSAGE: "This doesn't look like an email.",
			},
		},

		KEEP_PHONE_HANDY:
			"(Keep your Cell Phone handy, our insurance verifier may be calling to confirm your insurance)",
		TITLE_AND_REGISTRATION_INFORMATION:
			"Your title and registration information below should mirror your driver's license.",
		IF_HAVE_COBUYER_OR_COSIGNER:
			"If you have a cobuyer or cosigner, we will ask about that in the next screen.",
		IS_THERE_COBUYER_FOR_PURCHASE:
			"Is there a Co-Buyer or Co-Signer on this purchase?",
		INPUT_COBUYER_INFORMATION:
			"If yes, please input their full name and address information here",
		IN_ADDITION_TO_YOU:
			"In addition to you, will anyone else be named on the registration of your new vehicle?",
		HAVE_COSIGNER: "Yes, I have a Co-Signer",
		JUST_ME: "No, it’s just me",
		NOT_COBUYER: "There is not a Co-Buyer/Co-Signer",
	},

	care_card_page: {
		CARE_CARD_TEXT:
			"Your vehicle is centric to everything in your life. It transports you and your precious cargo to and from work, sporting events, vacations, school dances and life’s other significant milestones. There’s no need to put off those needed repairs anymore! {{ DEALER_NAME }} can help you make sure your life doesn’t stop moving with their {{ CARE_CARD_NAME }}",
		INQUIRE_TODAY: "Inquire today with your Sales or Finance Professional!",
	},

	collision_center_page: {
		AFTER_CAR_ACCIDENT: "The 10 Things You Should Do After a Car Accident",
		WE_WILL_TEXT_YOU:
			"We'll text you the contact information and these 10 important steps so that you will have it safe and secure in your phone should you need it!",
		"RULE#1": "#1 – Make sure everyone’s OK and call 911 if injuries warrant",
		"RULE#2": "#2 – Keep yourself and others safe by protecting the scene",
		"RULE#3": "#3 – Call the Police, regardless of injuries or fault",
		"RULE#4":
			"#4 – Document the details of the event while fresh – use your smartphone",
		"RULE#5": "#5 – Exchange information with all parties",
		"RULE#6": "#6 – Report the accident once the police arrive",
		"RULE#7": "#7 – Take pictures of all vehicles involved and the scene",
		"RULE#8": "#8 – Call Your Insurance Company and/or Agent",
		"RULE#9": "#9 – Go get checked out at your local care facility",
		"RULE#10": "#10 – Keep a file of all the accident related documentation",
	},

	common: {
		YES: "Yes",
		NO: "No",
		BACK: "Back",
		NEXT: "Next",
		NO_THANK_YOU: "No, Thank You",
		"YES!": "Yes!",
		SCROLL_TO_REVEAL_MORE: "scroll to reveal more",
		PLEASE_SCROLL: "Please Scroll to Continue",
	},
	congratulation_page: {
		SALES_PROFESSIONAL_WAS_NOTIFIED:
			"Your sales professional has been notified and will be with you shortly!",
		RELAX_AND_ENJOY_VIDEOS: "Relax and enjoy some videos.",
		NOT_ALL_APPLICABLE_TO_ALL_MODELS:
			"Not all features are applicable to all models",
	},
	connected_car_page: {
		NO_CONNECTED_CAR_APP:
			"Sorry, we don't have a connected car app for {{vehicleMake}} vehicles.",
	},
	feature_focus_page: {
		FEEL_FREE_TO_CLICK_TO_HIGHLIGHT:
			"Feel free to click to highlight any of the vehicle information categories below that you’d like to learn more about during your final vehicle orientation on your <span class='font-bold'> {{ vehicleYear }} {{ vehicleMake}} {{ vehicleModel }} </span> delivery",
	},
	fsa_page: {
		EMAIL:
			"Please enter your email address for service appointment confirmation!",
		SCHEDULE_FSA: "Schedule your First Service Appointment",
		WHAT_TIME_WORKS_BEST: "Please select a time that works best for you!",
		WORRIED_ABOUT_COMMITMENT:
			"Worried about making a commitment {{ timeToAppointment }} months out? Don't! You'll be able to reschedule at your leisure as the date draws nearer.",
		SELECTED_DATE_AVAILABILITY:
			"Selected date availability: {{ engagedCapacity }}/{{ capacity }} slots taken.",
		SELECTED_FSA_FOR: "You have selected your First Service Appointment for",
		CHANGE_DATE_OR_TIME: "I want to change the Date or Time",
		AT: "at",
		SUN: "SUN",
		MON: "MON",
		TUE: "TUE",
		WED: "WED",
		THU: "THU",
		FRI: "FRI",
		SAT: "SAT",
		JANUARY: "January",
		FEBRUARY: "February",
		MARCH: "March",
		APRIL: "April",
		MAY: "May",
		JUNE: "June",
		JULY: "July",
		AUGUST: "August",
		SEPTEMBER: "September",
		OCTOBER: "October",
		NOVEMBER: "November",
		DECEMBER: "December",
	},
	insurance_page: {
		fields: {
			INSURANCE_STATUS: {
				LABEL: "Please select the insurance status that best reflects yours",
				ERROR_MESSAGE: "",
				CHOICES: {
					I_HAVE_INSURANCE: "I have insurance",
					I_AM_EXISTING_CUSTOMER:
						"I am an existing customer with {{ INHOUSE_AGENCY_NAME}} and I would like to replace/add this vehicle to my existing policy.",
					I_DO_NOT_HAVE_INSURANCE_SETUP: "I don't have insurance setup",
				},
			},
			NAME_OF_INSURED: { LABEL: "Name of Insured", ERROR_MESSAGE: "" },
			POLICY_NUMBER: { LABEL: "Policy Number", ERROR_MESSAGE: "" },
			POLICY_START_DATE: { LABEL: "Policy Start Date", ERROR_MESSAGE: "" },
			POLICY_END_DATE: { LABEL: "Policy End Date", ERROR_MESSAGE: "" },
			EMAIL: { LABEL: "Email", ERROR_MESSAGE: "" },
			CITY: { LABEL: "City", ERROR_MESSAGE: "" },
			STATE: { LABEL: "State", ERROR_MESSAGE: "" },
			COUNTY: { LABEL: "County", ERROR_MESSAGE: "" },
			ZIP_CODE: { LABEL: "Zip Code", ERROR_MESSAGE: "" },
			BUYER_ADDRESS: { LABEL: "Buyer Address", ERROR_MESSAGE: "" },
			BUYER_PHONE_NUMBER: { LABEL: "Buyer Phone Number", ERROR_MESSAGE: "" },
			INSURER: {
				LABEL: "{{ INHOUSE_AGENCY_NAME }} Insurer",
				ERROR_MESSAGE: "",
			},
			INSURANCE_CARRIER: {
				LABEL: "Insurance Carrier",
				ERROR_MESSAGE: "",
				PLACEHOLDER: "Select a carrier...",
			},
			CARRIER_ADDRESS: { LABEL: "Carrier Address", ERROR_MESSAGE: "" },
			AGENT_NAME: {
				LABEL: "Agent's First/Last Name (if no agent, please type 'DIRECT')",
				ERROR_MESSAGE: "",
			},
			AGENT_PHONE_NUMBER: { LABEL: "Agent Phone Number", ERROR_MESSAGE: "" },
			EMPTY_ERROR_MESSAGE: " ",
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Please enter a valid phone number",
			},
			CUSTOMER_BIRTHDAY_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "You need to select a date after {{- BIRTHDAY_MIN }}",
			},
			CUSTOMER_BIRTHDAY_MAX: {
				LABEL: "",
				ERROR_MESSAGE: "You need to select a date before {{- BIRTHDAY_MAX }}",
			},
			POLICY_START_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"You need to select a date after {{- POLICY_START_MIN }}",
			},
			POLICY_START_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"You need to select a date before {{- POLICY_START_MAX }}",
			},
			POLICY_EXPIRATION_MIN: {
				LABEL: "",
				ERROR_MESSAGE:
					"You need to select a date after {{- POLICY_EXPIRATION_MIN }}",
			},
			POLICY_EXPIRATION_MAX: {
				LABEL: "",
				ERROR_MESSAGE:
					"You need to select a date before {{- POLICY_EXPIRATION_MAX }}",
			},
			ENTER_VALID_DATE: {
				LABEL: "",
				ERROR_MESSAGE: "Please enter a valid date",
			},
			CUSTOMER_EMAIL_REQUIRED: {
				LABEL: "",
				ERROR_MESSAGE: "We need your email.",
			},
			CUSTOMER_EMAIL: {
				LABEL: "",
				ERROR_MESSAGE: "This doesn't look like an email.",
			},
		},
		I_DO_NOT_SEE_MY_CARRIER: "I do not see my carrier",
		POLICY_INFORMATION: "Policy Information",
		BUYER_INFORMATION: "Buyer Information",
		CARRIER_INFORMATION: "Carrier Information",
		AGENT_INFORMATION: "Agent Information",
	},
	referral_page: {
		NAME_1: "Referral #1 Name",
		NAME_2: "Referral #2 Name",
		NAME_3: "Referral #3 Name",
		CELL_PHONE: "Cell Phone",
		EMAIL: "Email",
	},
	review_page: {
		YOUR_FEEDBACK_IMPORTANT: "Your Feedback Is Important To Us!",
		HOW_YOU_RATE_US:
			"How would you rate your experience at {{ dealershipName }}, so far?",
		PLEASE_RATE_US: "Please Rate Us from 1 - 5 stars",
		WE_ARE_DISAPPOINTED:
			"We're disappointed your experience didn’t meet your expectations. We’d love to hear what we could have done to make your experience better.",
		WE_ARE_SORRY:
			"We’re sorry we fell just short of your expectations up till now. If you’re willing, we appreciate you sharing your thoughts on where we can improve.",
		WE_WILL_TEXT_YOU: "If so, we'll text you a link to make it easy!",
		SHARE_YOUR_EXPERIENCE:
			"Would you mind taking a moment to share your experience through a {{name}} review?",
		WE_APPRECIATE_REVIEW: "We appreciate the review!",
		REVIEW_LINK_WAS_TEXTED_TO_YOU:
			"The {{name}} review link has been texted to you. We greatly appreciate you sharing your experience!",
		CONTINUE_ON: "Continue On",
		LET_US_KNOW: "Let us know what we can do better",
		COMMENTS: "Comments...",
		CHANGE_MY_RATING: "Change my Rating",
		NO_COMMENT: "No Comment.",
		SEND_MY_FEEDBACK: "Send my feedback",
	},
	sales_page: {
		fields: {
			DMS_OMNI_NUMBER: {
				LABEL: "Enter a {{usesDealNumber}}Stock # or VIN #",
				ERROR_MESSAGE: "Enter a number to search.",
				BUTTON: "Load",
			},
			DMS_OMNI_NUMBER_RV: {
				LABEL: "Enter a Deal #",
				ERROR_MESSAGE: "Enter a number to search.",
				BUTTON: "Load",
			},
			DEAL_NUMBER: { LABEL: "Deal Number", ERROR_MESSAGE: "" },
			CHOOSE_DEAL_TYPE: { LABEL: "Choose Deal Type", ERROR_MESSAGE: "" },
			STOCK_NUMBER: {
				LABEL: "Stock Number",
				ERROR_MESSAGE: "What's being sold?",
				BUTTON: "Load from Inv",
			},
			VEHICLE_CLASS: {
				LABEL: "Vehicle Type",
				ERROR_MESSAGE: "What's vehicle type?",
				PLACEHOLDER: "Search vehicle class...",
			},
			SALES_MANAGER: {
				LABEL: "Salesperson #",
				PLACEHOLDER: "Search for a sales professional....",
			},
			SALES_PROFESSIONAL_FIRST_NAME: {
				LABEL: "Sales Professional First Name",
				ERROR_MESSAGE: "",
			},
			SALES_PROFESSIONAL_LAST_NAME: {
				LABEL: "Sales Professional Last Name",
				ERROR_MESSAGE: "",
			},
			CELL_PHONE_FOR_TEXT_ALERTS: {
				LABEL: "Cell Phone for Text Alerts",
				ERROR_MESSAGE: "",
			},

			FIRST_NAME: { LABEL: "", ERROR_MESSAGE: "First name is required" },
			LAST_NAME: { LABEL: "", ERROR_MESSAGE: "Last name is required" },

			DMS_DEAL_NUMBER: {
				LABEL: "DMS Deal Number",
				ERROR_MESSAGE: "Don't forget me!",
				BUTTON: "Load from DMS",
			},
			DESK_MANAGER: {
				LABEL: "Desk Manager",
				ERROR_MESSAGE: "Who's in charge of this operation?!",
				PLACEHOLDER: "Search sales manager...",
			},
			TRADE_IN_QUESTIONNAIRE: {
				LABEL: "",
				ERROR_MESSAGE: "Are they trading something in?",
			},
			SALES_CONSULTANT_1: {
				LABEL: "",
				ERROR_MESSAGE: "Leave me blank to donate your commission to charity.",
			},
			VIN: {
				LABEL: "Vehicle VIN #",
				ERROR_MESSAGE: "Punch in my genetic DNA please.",
			},
			VIN_MIN: { LABEL: "", ERROR_MESSAGE: "This is too short for a VIN." },
			VIN_MAX: { LABEL: "", ERROR_MESSAGE: "This is too long to be a VIN." },
			YEAR: {
				LABEL: "Year",
				ERROR_MESSAGE: "How many candles on my birthday cake?",
				PLACEHOLDER: "Select a year...",
			},
			MAKE: {
				LABEL: "Make",
				ERROR_MESSAGE: "Who made me?",
				PLACEHOLDER: "Select a make...",
				LOADING: "LOADING MAKES...",
			},
			MODEL: {
				LABEL: "Model",
				ERROR_MESSAGE: "Don't leave me hangin' here!",
				PLACEHOLDER: "Select a model...",
				LOADING: "LOADING MODELS...",
			},
			TRIM: {
				LABEL: "Trim",
				ERROR_MESSAGE: "Don't forget what's special about me.",
				PLACEHOLDER: "Select a trim...",
				MODEL_FIRST: "Select a model first.",
				LOADING: "LOADING TRIMS...",
			},
			DOES_HAVE_TRADE_IN: {
				LABEL: "Does this deal have a trade-in?",
				ERROR_MESSAGE: "",
				CHOICES: {
					YES: "Yes",
					NO: "No",
				},
			},
			DEALERSHIP_CHOICE: {
				LABEL: "",
				ERROR_MESSAGE: "Who are we doing this deal for?",
			},
			CONNECTED_CAR_PHONE_APP: {
				LABEL: "Connected Car Phone App",
				ERROR_MESSAGE: "Hey, am I a smart car?",
				CHOICES: {
					YES: "Yes",
					NO: "No",
				},
			},
			CONNECTED_PHONE_APP: {
				LABEL: "Connected Phone App",
				ERROR_MESSAGE: "Do I have a phone app?",
				CHOICES: {
					YES: "Yes",
					NO: "No",
				},
			},
			DIGITAL_DELIVERY_TYPE: {
				LABEL: "",
				ERROR_MESSAGE: "Which way do you wish to get notified?",
			},
			CUSTOMER_FIRST_NAME: {
				LABEL: "Customer First Name",
				ERROR_MESSAGE: "Ooops!  You missed me!",
			},
			CUSTOMER_LAST_NAME: {
				LABEL: "Customer Last Name",
				ERROR_MESSAGE: "Whoah!  Don't forget me!",
			},
			CUSTOMER_ZIP_CODE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "We are looking for a 5 digit Zip.",
			},
			CUSTOMER_ZIP_CODE: {
				LABEL: "Customer Zip Code",
				ERROR_MESSAGE: "ZipDeal needs the customer's ZipCode!",
			},
			CUSTOMER_CELL_PHONE_MIN: {
				LABEL: "",
				ERROR_MESSAGE: "Please enter a valid phone number",
			},
			CUSTOMER_CELL_PHONE: {
				LABEL: "Customer Cell Phone",
				ERROR_MESSAGE: "We need their digits!",
			},
			CUSTOMER_EMAIL: { LABEL: "", ERROR_MESSAGE: "What's their email?" },
			FINANCE_TYPE: {
				LABEL: "Finance Type",
				ERROR_MESSAGE: "Hey, how are they paying for me?",
				CHOICES: {
					FINANCE: "Finance",
					LEASE: "Lease",
					CASH: "Cash",
				},
			},
		},
		CANT_FIND_NAME: "Can’t find name? Add it now!",
		DO_NOT_HAVE_DEAL_NUMBER: "I don't have a Deal Number",
		TEXT_ALERT_TO: "Text alerts will go to",
		ODOMETER: "Odometer",
		ADD_SALES_PROFESSIONAL: "Add a new Sales Professional",
		MAYBE_NOT: "Maybe not.",
		ADD_ME: "Add me!",
	},
	welcome_page: {
		BY_CLICKING_NEXT:
			"*By clicking 'NEXT' you permit {{ DEALER_NAME }} to communicate important ownership information via text.",
	},
	radio_presets_page: {
		PRESETS: "Presets",
		CHOOSE_BELOW: "Choose #{{ number }} Below",
		CLICK_YOUR_STATION: "Click your station to add it!",
		CHOOSE_WISELY:
			"You can't choose that many presets! You'll have to remove one before adding another.  Choose wisely.",
	},
	zdzFsa: {
		DATE_AND_TIME: "Your first service appointment is {{ date }} at {{time}}.",
		INSTRUCTIONS:
			"You can add to the calendar of your choice by choosing from the dropdown below!",
		NOTE: "Note: To add to your iPhone's Apple Calendar then this page needs to be viewed with Safari.",
		NOT_FOUND: "First service appointment details not found",
		GREETING: "Hi, {{name}}!",
		EVENT_NAME: "First Service Appointment at {{dealership}}",
	},
	AddToCalendarComponent: {
		PLACEHOLDER: "Select Calendar",
	},
};

export default translationsEN;
