import Cookies from "universal-cookie";
import { assign, fromPromise, setup } from "xstate";

const cookies = new Cookies();
let zdSessionId = cookies.get("zd_mediasession");

function generateRandomString(length) {
	// Define the characters we want in our string
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

	// Initialize an empty string
	let result = "";

	// Loop over the length of the desired string
	for (let i = 0; i < length; i++) {
		// Get a random character from the characters string and add it to the result
		result += characters.charAt(Math.floor(Math.random() * characters.length));
	}

	// Return the random string
	return result;
}

if (!zdSessionId && typeof window !== "undefined") {
	const hostname = window.location.hostname;

	// Split the hostname into parts
	const parts = hostname.split(".");
	zdSessionId = generateRandomString(6);
	cookies.set("zd_mediasession", zdSessionId, {
		path: "/",
		domain: `.${parts.slice(-2).join(".")}`, // Get the last two parts of the hostname
		secure: true,
		sameSite: "none",
		expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 31), // 31 days
	});
}

export const DownloadSpeedTestMachine = setup({
	types: {
		context: {} as {
			url: string;
			hostname: string;
			error: null;
			startTime: null | number;
			numberOfTests: number;
			speeds: {
				speedMbps: number;
				startTime: number;
				endTime: number;
				fileSize: number;
				timeTaken: number;
			}[];
			testsExecuted: number;
			fileSize: number;
		},
		input: {} as {
			url: string;
			numberOfTests: number;
			hostname: string;
		},
		events: {} as { type: "start" },
	},
	actions: {
		resetStartTime: assign(({ context }) => {
			const startTime = new Date().getTime();
			return { startTime };
		}),
		calculateSpeed: assign(({ context, event }: { context; event }) => {
			return {
				speeds: [
					...context.speeds,
					{
						speedMbps: event.output.speedMbps,
						startTime: event.output.startTime,
						endTime: event.output.endTime,
						fileSize: event.output.fileSize,
						timeTaken: (event.output.endTime - event.output.startTime) / 1000, // time in seconds
					},
				],
				testsExecuted: context.testsExecuted + 1,
				fileSize: event.output.fileSize,
			};
		}),

		recordError: ({ context, event }, params) => {
			// Add your action code here
			// ...
		},
	},
	actors: {
		postResults: fromPromise(
			async ({
				input,
			}: {
				input: {
					hostname: string;
					speeds: {
						speedMbps: number;
						startTime: number;
						endTime: number;
						fileSize: number;
						timeTaken: number;
					}[];
					fileSize: number;
					url: string;
				};
			}) => {
				try {
					const averageSpeed =
						input.speeds.reduce((prev, cur) => prev + cur.speedMbps, 0) /
						input.speeds.length;
					await fetch(`https://${input.hostname}/dj/speedtest/`, {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							speed: `${averageSpeed.toFixed(2)} Mbps`,
							reason: `${zdSessionId} File Size: ${input.fileSize.toFixed(
								2,
							)} MB - ${input.url}`,
						}),
					});
				} catch {
					return { speedMbps: "failed to fetch" };
				}
			},
		),
		downloadFile: fromPromise(
			async ({
				input,
			}: {
				input: {
					url: string;
					startTime: number;
					hostname: string;
				};
			}) => {
				try {
					const response = await fetch(`${input.url}?ran=${Math.random()}`);
					const blob = await response.blob();
					const endTime = new Date().getTime();
					const timeTaken = (endTime - input.startTime) / 1000; // time in seconds
					const fileSize = blob.size / (1024 * 1024); // size in MB
					const speedMbps = (fileSize / timeTaken) * 8; // speed in Mbps
					return { speedMbps, fileSize, startTime: input.startTime, endTime };
				} catch {
					return { speedMbps: "failed to fetch" };
				}
			},
		),
	},
	guards: {
		shouldRepeat: ({ context, event }, params) => {
			return context.testsExecuted < context.numberOfTests;
		},
	},
}).createMachine({
	context: ({ input: { url, hostname, numberOfTests } }) => ({
		url,
		hostname,
		numberOfTests,
		speeds: [],
		error: null,
		startTime: null,
		testsExecuted: 0,
		fileSize: 0,
	}),
	id: "downloadSpeedTester",
	initial: "idle",
	states: {
		idle: {
			on: {
				start: "downloading",
			},
			description:
				"The initial state before any download test has been started.",
		},
		downloading: {
			entry: [
				{
					type: "resetStartTime",
				},
			],
			invoke: {
				input: ({ context }) => ({
					url: context.url,
					startTime: context.startTime,
					hostname: context.hostname,
				}),
				onDone: {
					target: "calculatingSpeed",
				},
				onError: {
					target: "failure",
					actions: {
						type: "recordError",
					},
				},
				src: "downloadFile",
			},
			description: "The state where the download is in progress.",
		},
		calculatingSpeed: {
			always: [
				{
					target: "decision",
				},
			],
			entry: [
				{
					type: "calculateSpeed",
				},
			],
			description: "The state where the download speed is being calculated.",
		},
		decision: {
			always: [
				{
					target: "randomDelay",
					guard: "shouldRepeat",
				},
				{
					target: "postingResults",
				},
			],
		},
		randomDelay: {
			after: {
				5000: "downloading",
			},
		},
		failure: {
			type: "final",
			description: "The state where the download speed test has failed.",
		},
		postingResults: {
			invoke: {
				src: "postResults",
				input: ({ context }) => ({
					url: context.url,
					hostname: context.hostname,
					speeds: context.speeds,
					fileSize: context.fileSize,
				}),
				onDone: {
					target: "success",
				},
				onError: {
					target: "failure",
					actions: {
						type: "recordError",
					},
				},
			},
		},
		success: {
			type: "final",
			description:
				"The state where the download speed test has successfully completed.",
		},
	},
	output: ({ context }) => {
		console.log(context.speeds);
		const averageSpeed =
			context.speeds.reduce((prev, cur) => prev + cur.speedMbps, 0) /
			context.speeds.length;
		return averageSpeed;
	},
});
